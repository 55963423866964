import { useCallback } from 'react';

import * as Storage from '@app/utils/storage';

export interface UserKeyValue {
  key: string;
  value: string;
}
type GetUserFunction = () => Promise<UserKeyValue | null>;

const useGetUser = (): GetUserFunction =>
  useCallback(async () => {
    const storage = await Storage.getAll();
    const userKey = Object.keys(storage).find((key) =>
      key.includes('@@user@@')
    );
    if (userKey) {
      const user = await Storage.getItem(userKey);
      if (user) {
        return { key: userKey, value: user };
      }
    }

    return null;
  }, []);

export default useGetUser;
