import { useQuery } from '@tanstack/react-query';

import {
  AdvancedSearchFormInputs,
  SortOption,
} from '@app/services/files/types';
import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

interface UseFilesOptions {
  filters?: Partial<AdvancedSearchFormInputs>;
  sortBy?: SortOption;
  sortDirection?: 'asc' | 'desc';
  path?: string;
}

const useFiles = ({
  filters,
  sortBy,
  sortDirection,
  path,
}: UseFilesOptions = {}) => {
  const { get } = useFilesApi();
  const query = useQuery({
    queryFn: () => get({ filters, sortBy, sortDirection, path }),
    queryKey: ['files', filters, sortBy, sortDirection, path],
    select: (data) =>
      !data ? [] : data.data.map((file) => fileResponseToFile(file)),
  });

  return query;
};

export default useFiles;
