import { useAutocomplete } from '@mui/base/useAutocomplete';
import { UseAutocompleteProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import useCreateTag from '@app/hooks/useCreateTag';
import { Tag } from '@app/services/files/types';
import CheckIcon from '@assets/layout/check.svg?react';

import TagChip from './tag-chip';

interface TagsInputProps {
  options: Tag[];
  value: Tag[];
  onChange: (value: Tag[]) => void;
  tagClass?: string;
  inputClass?: string;
  onlySearch?: boolean;
  autoCompleteProps?: Omit<
    UseAutocompleteProps<Tag, true, false, boolean>,
    'options'
  >;
  onInputValueChange?: (value: string) => void;
}

const TagsInput = forwardRef<unknown, TagsInputProps>(
  (
    {
      options = [],
      value,
      onChange,
      inputClass,
      onlySearch = false,
      autoCompleteProps,
      onInputValueChange,
    },
    _ref
  ) => {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const createTag = useCreateTag();

    const inputClasses = twMerge(
      'flex-grow border-0 bg-white px-2 text-lg outline-none flex-1',
      inputClass
    );

    const parseChanges = (newValues: Array<string | Tag>) => {
      onInputValueChange?.('');
      onChange(
        newValues.map((newValue) =>
          typeof newValue === 'string' ? createTag(newValue) : newValue
        )
      );
    };

    const {
      getRootProps,
      getInputProps,
      getTagProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      setAnchorEl,
    } = useAutocomplete({
      id: 'tags-input',
      defaultValue: [],
      multiple: true,
      options,
      getOptionLabel: (option) =>
        typeof option === 'string' ? option : option.name,
      autoComplete: true,
      value,
      freeSolo: !onlySearch,
      onChange: (_, newValue) => parseChanges(newValue),
      isOptionEqualToValue: (option, valueToTest) =>
        option.id === valueToTest.id,
      disableCloseOnSelect: true,
      ...autoCompleteProps,
    });

    return (
      <div className="relative text-base text-opacity-65" ref={setAnchorEl}>
        <div className="flex max-h-32 flex-wrap gap-2 overflow-auto">
          {value.map((option, index) => {
            const { onDelete, key, ...props } = getTagProps({ index });

            return (
              <TagChip
                data-testid={`tag-${index}`}
                key={key}
                tag={option}
                {...props}
                onDelete={onDelete}
              />
            );
          })}
        </div>
        <div
          {...getRootProps()}
          className="mt-sm flex rounded-sm border-0 border-b bg-white p-1"
        >
          <input
            className={inputClasses}
            data-testid="new-tags-input"
            {...getInputProps()}
            onChange={(e) => {
              onInputValueChange?.(e.target.value);
              getInputProps().onChange?.(e);
            }}
            placeholder={t('components.tagInput.placeholder')}
          />
        </div>
        {groupedOptions.length > 0 ? (
          <ul
            className="max-h-250 absolute z-10 w-full list-none overflow-auto bg-white p-0 shadow-md"
            {...getListboxProps()}
          >
            {(groupedOptions as Tag[]).map((option, index) => (
              <li
                className={`px-md py-sm flex cursor-pointer transition-colors
                  duration-100 hover:bg-gray-50 aria-[selected='true']:bg-gray-100 aria-[selected='true']:font-medium`}
                {...getOptionProps({ option, index })}
                key={option.id}
              >
                <span className="flex-grow">{option.name}</span>
                {value.some((selected) => selected.id === option.id) ? (
                  <CheckIcon
                    fontSize="small"
                    title="check-icon"
                    color={palette.primary.main}
                  />
                ) : null}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }
);

export default TagsInput;
