import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// import CloudSyncIcon from '@assets/files/cloud-sync.svg?react';
import DownloadIcon from '@assets/files/download.svg?react';
import HistoryIcon from '@assets/files/history.svg?react';
import PreviewIcon from '@assets/files/viewer.svg?react';
import ButtonBase from '@components/button-base';

import FileDetailsLockButton from './file-details-lock-button';
import FileDetailsUpdateFileButton from './file-details-update-file-button';

interface FileDetailsButtonsProps {
  onDownloadClick: () => void;
  onHistoryClick: () => void;
  onPreviewClick: () => void;
  onCloudSyncClick: () => void;
}

const ICON_SIZE = 16;

const FileDetailsButtons = ({
  // onCloudSyncClick,
  onDownloadClick,
  onPreviewClick,
  onHistoryClick,
}: FileDetailsButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt-md flex flex-col" data-tour="preview-document-step-3">
      <ButtonBase
        focusRipple
        onClick={onPreviewClick}
        className="!p-sm !justify-start"
        data-tour="preview-document-step-2"
      >
        <PreviewIcon
          className="mr-sm text-black"
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
        <Typography>{t('fileList.fileDetails.buttons.preview')}</Typography>
      </ButtonBase>
      <ButtonBase
        focusRipple
        onClick={onDownloadClick}
        className="!p-sm !justify-start"
        data-tour="preview-document-step-4"
      >
        <DownloadIcon className="mr-sm" width={ICON_SIZE} height={ICON_SIZE} />
        <Typography>{t('fileList.fileDetails.buttons.download')}</Typography>
      </ButtonBase>
      <ButtonBase
        focusRipple
        onClick={onHistoryClick}
        className="!p-sm !justify-start"
      >
        <HistoryIcon className="mr-sm" width={ICON_SIZE} height={ICON_SIZE} />
        <Typography>{t('fileList.fileDetails.buttons.history')}</Typography>
      </ButtonBase>
      {/* <ButtonBase
        focusRipple
        onClick={onCloudSyncClick}
        className="!p-sm !justify-start"
      >
        <CloudSyncIcon className="mr-sm" width={ICON_SIZE} height={ICON_SIZE} />
        <Typography>{t('fileList.fileDetails.buttons.cloudSync')}</Typography>
      </ButtonBase> */}
      <FileDetailsUpdateFileButton />
      <FileDetailsLockButton />
    </div>
  );
};

export default FileDetailsButtons;
