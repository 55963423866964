import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useExtendLock = () => {
  const { extendLock } = useFilesApi();
  const mutation = useMutation({
    mutationFn: extendLock,
    mutationKey: ['file-lock-extend'],
  });

  return mutation;
};

export default useExtendLock;
