import { useMutation } from '@tanstack/react-query';

import { useUpdateLockedFiles } from '@app/hooks/useLockedFiles';
import useFilesApi from '@app/services/files/useFilesApi';

const useLockFile = () => {
  const { lockFile, unlockFile } = useFilesApi();
  const dispatch = useUpdateLockedFiles();

  const mutation = useMutation<void, Error, { fileId: string; lock: boolean }>({
    mutationFn: ({ fileId, lock }) => {
      if (lock) {
        return lockFile({ fileId });
      }

      return unlockFile({ fileId });
    },
    mutationKey: ['file-lock'],
    onSuccess: (_, { fileId, lock }) => {
      if (lock) {
        dispatch({
          type: 'ADD_FILE',
          payload: fileId,
        });
      } else {
        dispatch({
          type: 'REMOVE_FILE',
          payload: fileId,
        });
      }
    },
  });

  return mutation;
};

export default useLockFile;
