import { PanInfo, motion, useAnimationControls } from 'framer-motion';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import useMoveableToFolder, {
  MoveableElements,
} from '../pages/files/hooks/useMoveableToFolder';

interface MoveableToFolderProps {
  children: React.ReactNode;
  onFileMove: (elements: MoveableElements) => void;
  onFolderHover: (elements: MoveableElements) => void;
  elementId: string;
  elementName: string;
  onDragEnd?: (event: PointerEvent, info: PanInfo) => void;
  onDragStart?: (event: PointerEvent, info: PanInfo) => void;
}

const MoveableToFolder = forwardRef<HTMLDivElement, MoveableToFolderProps>(
  (
    {
      children,
      onFileMove,
      onFolderHover,
      elementId,
      onDragEnd,
      onDragStart,
      elementName,
    },
    ref
  ) => {
    const animationControls = useAnimationControls();
    const location = useLocation();
    const initialPathname = useRef<string>(location.pathname);
    const [isHolding, setIsHolding] = useState(false);

    const { dragging, dragEnded } = useMoveableToFolder({
      elementName,
      elementId,
      onFileMove,
      onFolderHover,
    });

    const classes = useMemo(
      /* v8 ignore next */
      () => twMerge('relative', isHolding ? 'z-[80]' : ''),
      [isHolding]
    );

    /* v8 ignore start */
    const handleDragStart = (event: PointerEvent, info: PanInfo) => {
      setIsHolding(true);
      onDragStart?.(event, info);
      initialPathname.current = location.pathname;
    };

    const handleDrag = (event: PointerEvent, info: PanInfo) => {
      dragging(event, info);
    };

    const handleDragEnd = (event: PointerEvent, info: PanInfo) => {
      setIsHolding(false);
      dragEnded(event);
      onDragEnd?.(event, info);

      if (location.pathname === initialPathname.current) {
        animationControls.set({
          x: 0,
          y: 0,
        });
      }
    };
    /* v8 ignore stop */

    useEffect(() => {
      animationControls
        .start({
          opacity: 1,
        })
        .catch((_) => {});
    }, [animationControls]);

    return (
      <motion.div
        layout
        exit={{ scale: 0, opacity: 0 }}
        initial={{ opacity: 0 }}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        animate={animationControls}
        dragMomentum={false}
        onDrag={handleDrag}
        whileTap={{ scale: 0.9 }}
        drag
        data-testid="moveable-to-folder"
        ref={ref}
        id={elementId}
        className={classes}
      >
        {children}
      </motion.div>
    );
  }
);

export default MoveableToFolder;
