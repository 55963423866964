import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { forwardRef, MouseEventHandler } from 'react';

interface PressableIconProps
  extends Omit<TooltipProps, 'onClick' | 'title' | 'children'> {
  icon: React.ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  altText?: string;
  className?: string;
}

const PressableIcon = forwardRef<HTMLButtonElement, PressableIconProps>(
  ({ icon, onClick, altText, className, ...props }, ref) => (
    <Tooltip arrow title={altText} {...props}>
      <IconButton ref={ref} className={className} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  )
);

export default PressableIcon;
