import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Modal } from '@mui/material';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import getDocumentRenderer, {
  DocumentRendererContext,
} from '@app/document-renderers/document';
import getImageRenderer from '@app/document-renderers/image';
import useAlert from '@app/hooks/useAlert';
import { File } from '@app/services/files/types';
import ErrorIcon from '@assets/shared/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import NoPreview from '@components/no-preview';
import StopClickPropagation from '@components/stop-click-propagation';

import useDeleteFilePreview from '../hooks/useDeleteFilePreview';
import useFilePreview from '../hooks/useFilePreview';

import FilePreviewModalHeader from './file-preview-modal-header';

interface FilePreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: File;
}

const FilePreviewModal = ({ file, onClose, isOpen }: FilePreviewModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [scale, setScale] = useState(1);
  const { displayGlobal } = useAlert();

  const { isLoading, isRefetching, data, isError, refetch } = useFilePreview(
    file.id
  );
  const { mutateAsync: deletePreview } = useDeleteFilePreview();

  const handleDownload = () => {};

  const handleSync = () => {};

  const handleManageVersions = () => {
    navigate(`/file/${file.id}/versions`);
  };

  /* v8 ignore start */
  const handleZoomIn = () => {
    if (scale < 4) {
      setScale((currentScale) => currentScale + 0.5);
    }
  };

  const handleZoomOut = () => {
    if (scale > 0.5) {
      setScale((currentScale) => currentScale - 0.5);
    }
  };
  /* v8 ignore stop */

  const handleEdit = async () => {
    const error = await window.file.open('sample.txt');
    if (error) {
      handleClose();
      displayGlobal({
        text: t('fileList.filePreviewModal.edit.error'),
        type: 'error',
      });
    }
  };

  const handleRetry: MouseEventHandler<HTMLSpanElement> = useCallback(
    (e) => {
      e.stopPropagation();
      void refetch();
    },
    [refetch]
  );

  const handleClose = () => {
    void deletePreview(file.id);
    onClose();
  };

  const content = useMemo(() => {
    if (isLoading || isRefetching) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <ErrorIcon className="mb-md mx-auto" />
          <p className="mx-lg mb-md text-center text-lg font-medium text-white">
            <Trans i18nKey="fileList.filePreviewModal.error">
              Oops! We could not load the file.
              <span className="cursor-pointer underline" onClick={handleRetry}>
                Try Again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport className="text-white" />
        </div>
      );
    }

    if (data) {
      return (
        <div
          className={`scrollbar-thumb-rounded-full scrollbar-thin
            scrollbar-thumb-gray-300 scrollbar-track-black/1 0 h-[93vh] overflow-auto`}
        >
          <div
            className="pb-screen m-auto w-fit"
            onClick={(e) => e.stopPropagation()}
            data-testid="preview-viewer"
          >
            <DocumentRendererContext.Provider value={{ scale }}>
              <DocViewer
                className="mt-md !bg-transparent"
                config={{
                  header: { disableHeader: true },
                  noRenderer: {
                    overrideComponent: NoPreview,
                  },
                }}
                documents={[{ uri: data.link, fileType: file.type }]}
                prefetchMethod="GET"
                pluginRenderers={[
                  ...DocViewerRenderers,
                  getImageRenderer('overflow-hidden'),
                  getDocumentRenderer('', { allPages: true }),
                ]}
              />
            </DocumentRendererContext.Provider>
          </div>
        </div>
      );
    }
  }, [data, file.type, handleRetry, isError, isLoading, isRefetching, scale]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      className="p-screen pr-2"
      id="file-preview-modal"
      slotProps={{
        backdrop: {
          className: '!bg-black/95',
        },
      }}
    >
      <StopClickPropagation>
        <div onClick={handleClose} className="flex h-full flex-col">
          <FilePreviewModalHeader
            onDownload={handleDownload}
            onSync={handleSync}
            onManageVersions={handleManageVersions}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            onEdit={handleEdit}
            onClose={handleClose}
            file={file}
          />
          {content}
        </div>
      </StopClickPropagation>
    </Modal>
  );
};

export default FilePreviewModal;
