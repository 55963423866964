import { HttpStatusCode } from 'axios';

import { UnsuccessfulResponse } from '@filot/types/api';

import AppError from './error';

const DEFAULT_MESSAGE = 'Client Axios error';

export default class RequestError<T> extends AppError {
  status?: HttpStatusCode;
  description?: string;
  message: string;
  code?: string;
  response?: UnsuccessfulResponse<T>;
  isClient: boolean = false;
  originUrl: string;

  constructor(error: {
    description?: string;
    message?: string;
    status?: HttpStatusCode;
    code?: string;
    response?: UnsuccessfulResponse<T>;
    originUrl: string;
  }) {
    super(error.message ?? DEFAULT_MESSAGE);
    this.status = error.status;
    this.description = error.description;
    this.message = error.message ?? DEFAULT_MESSAGE;
    this.code = error.code;
    this.isClient = error.status
      ? error.status >= HttpStatusCode.BadRequest &&
        error.status < HttpStatusCode.InternalServerError
      : this.isClient;
    this.response = error.response;
    this.originUrl = error.originUrl;
  }

  getResponseError(): UnsuccessfulResponse<T> {
    return {
      message: this.response?.message ?? '',
      data: this.response?.data ?? undefined,
      success: this.response?.success ?? false,
    };
  }
}
