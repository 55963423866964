import {
  createContext,
  Dispatch,
  ProviderProps,
  useContext,
  useReducer,
} from 'react';

import { Action, ActionWithoutPayload, Reducer } from '@filot/types/context';

type AddLockedFileAction = Action<'ADD_FILE', string>;
type RemoveLockedFileAction = Action<'REMOVE_FILE', string>;
type ResetLockedFilesAction = ActionWithoutPayload<'RESET_FILES'>;

type LockedFilesActions =
  | AddLockedFileAction
  | RemoveLockedFileAction
  | ResetLockedFilesAction;

interface LockedFilesState {
  lockedFileIds: string[];
}

const initialState: LockedFilesState = {
  lockedFileIds: [],
};

export const LockedFilesStateContext = createContext(initialState);

export const LockedFilesDispatchContext = createContext<
  Dispatch<LockedFilesActions>
>(() => {});

const folderReducer: Reducer<LockedFilesState, LockedFilesActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'ADD_FILE':
      return {
        ...state,
        lockedFileIds: [...state.lockedFileIds, action.payload],
      };
    case 'REMOVE_FILE':
      return {
        ...state,
        lockedFileIds: state.lockedFileIds.filter(
          (fileId) => fileId !== action.payload
        ),
      };
    case 'RESET_FILES':
      return {
        ...state,
        lockedFileIds: [],
      };
    default:
      return state;
  }
};

export const LockedFilesProvider = ({
  children,
}: Omit<ProviderProps<string[]>, 'value'>) => {
  const [state, dispatch] = useReducer(folderReducer, initialState);

  return (
    <LockedFilesDispatchContext.Provider value={dispatch}>
      <LockedFilesStateContext.Provider value={state}>
        {children}
      </LockedFilesStateContext.Provider>
    </LockedFilesDispatchContext.Provider>
  );
};

export const useLockedFiles = () => useContext(LockedFilesStateContext);
export const useUpdateLockedFiles = () =>
  useContext(LockedFilesDispatchContext);
