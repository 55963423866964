import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import Error from '@assets/organizations/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';

import Empty from './components/empty';
import OrganizationInviteAlert from './components/organization-invite-alert';
import OrganizationTabs from './components/organization-tabs';
import useOrganization from './hooks/useOrganization';
import { OrganizationContext } from './hooks/useOrganizationContext';

const Organizations = () => {
  const { user } = useAuth0();
  const { data, isError, isLoading, refetch } = useOrganization(user?.sub);

  const Content = () => {
    if (isLoading) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="mb-lg flex flex-1 flex-col items-center justify-center">
          <Error className="mb-lg" />
          <Typography className="!mr-1 !text-lg">
            <Trans i18nKey="organization.error">
              Oops! We could not load your organization.
              <span
                className="cursor-pointer text-lg underline"
                onClick={() => refetch()}
              >
                Try again?
              </span>
            </Trans>
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    if (data === null) {
      return (
        <div className="gap-y-lg p-screen relative flex flex-1 flex-col items-center">
          <OrganizationInviteAlert />
          <Empty />
        </div>
      );
    }

    if (data) {
      return (
        <div className="p-screen w-full">
          <OrganizationContext.Provider
            value={{ ...data, picture: data.branding?.logo_url }}
          >
            <OrganizationTabs />
          </OrganizationContext.Provider>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-1 overflow-x-auto">
      <Content />
    </div>
  );
};

export default Organizations;
