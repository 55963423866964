import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  Chip,
} from '@mui/material';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import useAppJoyride from '@app/hooks/useAppJoyride';
import { File, Tag } from '@app/services/files/types';
import { TagWithFiles } from '@app/services/tags/types';
import ExpandMoreIcon from '@assets/files/expand-more.svg?react';

import TagItemFiles from './tag-item-files';
import TagListItemContextMenu from './tag-list-item-context-menu';

interface TagListItemProps {
  tag: TagWithFiles;
  files: File[];
  expanded: AccordionProps['expanded'];
  onChange: AccordionProps['onChange'];
  onFileClick: (file: File) => void;
  onOpenMergeTag?: (tag: Tag) => void;
}

const TagListItem = ({
  tag,
  files,
  expanded,
  onChange,
  onFileClick,
  onOpenMergeTag,
}: TagListItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { advanceStep } = useAppJoyride();

  return (
    <>
      <TagListItemContextMenu
        anchorElement={ref.current}
        onOpenMergeTag={() => onOpenMergeTag?.(tag)}
      />
      <div ref={ref}>
        <Accordion
          className="!shadow-none"
          expanded={expanded}
          onChange={onChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${tag.id}-content`}
            id={`${tag.id}-header`}
            className="!m- !px-md !py-2"
          >
            <div className="gap-x-sm flex items-center" data-tour="tags-step-4">
              <Link
                to={tag.id}
                state={{ tag }}
                onClick={() => advanceStep(6, 3)}
                className="text-lg hover:underline"
              >
                <Chip
                  key={tag.id}
                  color="default"
                  className="!mr-2 !mt-2 !h-auto !max-w-40 overflow-ellipsis !rounded-full !px-0 !py-1"
                  style={{
                    backgroundColor: tag.backgroundColor,
                    color: tag.textColor,
                  }}
                  label={tag.name}
                />
              </Link>
            </div>
          </AccordionSummary>
          <TagItemFiles files={files} onFileClick={onFileClick} tag={tag} />
        </Accordion>
      </div>
    </>
  );
};

export default TagListItem;
