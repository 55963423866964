import { isRunningOnElectron } from './environment';

export const setItem = async (key: string, value: string) => {
  if (isRunningOnElectron()) {
    await window.storage.setItem(key, value);
  } else {
    localStorage.setItem(key, value);
  }
};

export const getItem = async (key: string) => {
  if (isRunningOnElectron()) {
    return await window.storage.getItem(key);
  }

  return localStorage.getItem(key);
};

export const deleteItem = async (key: string) => {
  if (isRunningOnElectron()) {
    await window.storage.deleteItem(key);
  } else {
    localStorage.removeItem(key);
  }
};

export const getAll = async () => {
  if (isRunningOnElectron()) {
    return await window.storage.getAll();
  } else {
    const entries: Record<string, string> = {};

    for (let i = 0; i < localStorage.length; i += 1) {
      const key = localStorage.key(i);
      if (key) {
        entries[key] = localStorage.getItem(key) || '';
      }
    }

    return entries;
  }
};

export const deleteUser = async () => {
  const all = await getAll();

  const userKey = Object.keys(all).find((key) => key.includes('@@user@@'));
  if (userKey) {
    await deleteItem(userKey);
  }
};
