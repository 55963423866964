import { AvatarGroup, TableCell, TableRow } from '@mui/material';

import { File } from '@app/services/files/types';
import FileIcon from '@components/file-icon';
import UserAvatar from '@components/user-avatar';

interface TagDetailsFilesTableRowProps {
  file: File;
  onClick: () => void;
}

const TagDetailsFilesTableRow = ({
  file,
  onClick,
}: TagDetailsFilesTableRowProps) => (
  <TableRow onClick={onClick} className="cursor-pointer">
    <TableCell className="!py-2">
      <div className="gap-x-sm flex items-center">
        <FileIcon type={file.type} width={32} height={32} />
        <p className="text-base">{file.fileName}</p>
      </div>
    </TableCell>
    <TableCell className="!py-2">
      <UserAvatar id={file.owner} />
    </TableCell>
    <TableCell className="!py-2">
      <div className="flex items-center">
        <AvatarGroup max={4}>
          {Object.keys(file.people).map((userId) => (
            <UserAvatar key={userId} id={userId} />
          ))}
        </AvatarGroup>
      </div>
    </TableCell>
  </TableRow>
);

export default TagDetailsFilesTableRow;
