import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import { FileResponse, LockState } from '@app/services/files/types';
import AddTime from '@assets/files/add-time.svg?react';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';
import { SuccessfulResponse } from '@filot/types/api';

import useExtendLock from '../hooks/useExtendLock';

import { FileDetailsContext } from './file-details';

interface ExtendLockButtonProps {
  lock: LockState;
}

const ExtendLockButton = ({ lock }: ExtendLockButtonProps) => {
  const { t } = useTranslation();
  const file = useContext(FileDetailsContext);
  const queryClient = useQueryClient();
  const { mutateAsync: extendLock, isPending } = useExtendLock();
  const { displayGlobal } = useAlert();

  const handleAddTime = async () => {
    if (file && !isPending) {
      try {
        await extendLock({ fileId: file.id });
        const nextUnlockDate = DateTime.fromISO(lock.unlocksAt).plus({
          minutes: 30,
        });
        queryClient.setQueryData(
          ['file-details', file.id],
          (oldData: SuccessfulResponse<FileResponse>) =>
            oldData
              ? {
                  ...oldData,
                  data: {
                    ...oldData.data,
                    lock_state: {
                      ...oldData.data.lock_state,
                      unlocks_at: nextUnlockDate.toISO(),
                    },
                  },
                }
              : undefined
        );
        window.notification.cancel();
      } catch (e) {
        displayGlobal({
          text: t('fileList.fileDetails.buttons.lock.extend.error'),
          type: 'error',
        });
      }
    }
  };

  return (
    <PressableIcon
      icon={isPending ? <Loading size={14} /> : <AddTime />}
      altText={t('fileList.fileDetails.buttons.lock.extend.button')}
      onClick={handleAddTime}
      placement="top"
    />
  );
};

export default ExtendLockButton;
