import { useQuery } from '@tanstack/react-query';

import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

const useFolderDetails = (folderId: string) => {
  const { getFolderDetails } = useFilesApi();

  const query = useQuery({
    queryFn: () => getFolderDetails(folderId),
    queryKey: ['folder-details', folderId],
    select: ({ data }) => fileResponseToFile(data),
  });

  return query;
};

export default useFolderDetails;
