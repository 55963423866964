import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInvitation from '../hooks/useInvitation';

import AcceptInviteModal from './accept-invite-modal';

const OrganizationInviteAlert = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useInvitation();

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="xl-height:px-screen xl-height:absolute relative w-full px-0">
        <div className="bg-primary/5 p-md flex items-center justify-between rounded-md">
          <span>
            <p className="text-lg">
              {t('organization.invitation.text', {
                organizationName: data.organization.displayName,
              })}
            </p>
            <p className="text-lg">
              {t('organization.invitation.accept', {
                relative: DateTime.fromISO(data.expiresAt).toRelativeCalendar(),
              })}
            </p>
          </span>
          <Button variant="contained" onClick={() => setIsOpen(true)}>
            {t('organization.invitation.button')}
          </Button>
        </div>
      </div>
      <AcceptInviteModal
        organizationName={data.organization.displayName}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        invitationId={data.id}
      />
    </>
  );
};

export default OrganizationInviteAlert;
