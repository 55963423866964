import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { File } from '@app/services/files/types';
import { TagWithFiles } from '@app/services/tags/types';
import RightChevron from '@assets/files/right_chevron.svg?react';
import FileCard from '@components/file-card';
import PressableIcon from '@components/pressable-icon';
import FolderCard from '@pages/files/components/folder-card';

interface TagItemFilesProps {
  files: File[];
  onFileClick: (file: File) => void;
  tag: TagWithFiles;
}

const TagItemFiles = ({ files, tag, onFileClick }: TagItemFilesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleTagNavigate = () => {
    navigate(tag.id, { state: { tag } });
  };

  if (files.length === 0) {
    return (
      <div className="p-md gap-8 overflow-x-auto">
        <p className="ml-md">{t('tags.noFiles')}</p>
      </div>
    );
  }

  return (
    <div className="p-md grid auto-cols-[200px] grid-flow-col gap-8 overflow-x-auto">
      {files
        .slice(0, 8)
        .map((file) =>
          file.type === 'folder' ? (
            <FolderCard key={file.id} folder={file} onClick={onFileClick} />
          ) : (
            <FileCard key={file.id} file={file} onFileClick={onFileClick} />
          )
        )}
      <div className="pt-sm flex items-center">
        <PressableIcon
          icon={
            <div className="flex flex-col items-center justify-center rounded-full">
              <RightChevron title="more-files" />
              {files.length > 8 ? (
                <p className="mt-sm text-base text-black">
                  {t('tags.allFiles')}
                </p>
              ) : null}
            </div>
          }
          onClick={handleTagNavigate}
        />
      </div>
    </div>
  );
};

export default TagItemFiles;
