import { useMutation } from '@tanstack/react-query';

import { UploadFileRequest } from '@app/services/files/types';
import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

const useUploadFile = () => {
  const { uploadFile } = useFilesApi();

  const mutation = useMutation({
    mutationFn: async (variables: UploadFileRequest) => {
      const { data } = await uploadFile(variables);

      return fileResponseToFile(data);
    },
    mutationKey: ['file-upload'],
  });

  return mutation;
};

export default useUploadFile;
