import { useMutation } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useAcceptInvitation = () => {
  const { acceptInvitation } = useOrganizationApi();
  const mutation = useMutation({
    mutationFn: acceptInvitation,
    mutationKey: ['organization-accept-invitation'],
  });

  return mutation;
};

export default useAcceptInvitation;
