import { User } from '@auth0/auth0-react';

import { encodeToBase64 } from '@app/utils/base64';

import { UserKeyValue } from './useGetUser';

interface UserStore {
  decodedToken: {
    user: User;
  };
  id_token: string;
}

const useRedirectToAppUrlGenerator =
  () =>
  (token: string, user: UserKeyValue): string => {
    const userId = (JSON.parse(user.value) as UserStore).decodedToken.user.sub;

    return `filot://login?token=${encodeURIComponent(
      encodeToBase64(token)
    )}&key=${user.key}&user=${encodeURIComponent(userId ?? '')}`;
  };

export default useRedirectToAppUrlGenerator;
