import { useEffect } from 'react';

import useAuth from '@app/hooks/useAuth';

const LoadLogin = () => {
  const { login } = useAuth();

  useEffect(() => {
    void login({ web: false });
  }, [login]);

  return <></>;
};

export default LoadLogin;
