import {
  FormControl,
  Button,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  ListItemText,
  Checkbox,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  FileAccessLevel,
  ShareFile,
  ShareUserOrGroup,
} from '@app/services/files/types';
import GroupsUserInput from '@components/groups-user-input';
import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';

interface ShareFileFormProps {
  onSubmit: SubmitHandler<ShareFile>;
  initialUsers: ShareUserOrGroup[] | undefined;
}

const ShareFileForm = ({ onSubmit, initialUsers }: ShareFileFormProps) => {
  const { handleSubmit, control, formState, watch, setValue } =
    useForm<ShareFile>({
      defaultValues: {
        users: initialUsers ?? [],
        startDate: DateTime.now().startOf('day'),
        expirationDate: null,
        access: ['read'],
      },
    });
  const startDate = watch('startDate');
  const access = watch('access');
  const { t } = useTranslation();
  const { isAvailable } = useUserInputContext();

  const options = useMemo<Array<{ value: FileAccessLevel; text: string }>>(
    () => [
      {
        value: 'read',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.viewer'
        ),
      },
      {
        value: 'write',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.editor'
        ),
      },
      {
        value: 'read_notes',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.readNotes'
        ),
      },
      {
        value: 'create_notes',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.createNotes'
        ),
      },
      {
        value: 'delete',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.delete'
        ),
      },
      {
        value: 'update_permissions',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.updatePermissions'
        ),
      },
    ],
    [t]
  );

  const handleSelectPermission = ({
    target: { value },
  }: SelectChangeEvent<FileAccessLevel[]>) => {
    const newValue =
      typeof value === 'string'
        ? (value.split(', ') as FileAccessLevel[])
        : value;

    if (newValue.includes('write') && !newValue.includes('read')) {
      setValue('access', [...newValue, 'read']);
    } else if (
      newValue.includes('create_notes') &&
      !newValue.includes('read_notes')
    ) {
      setValue('access', [...newValue, 'read_notes']);
    } else {
      setValue('access', newValue);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mt-md flex flex-1 flex-col justify-between"
    >
      <div>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="users"
            control={control}
            rules={{
              validate: (value) => value.length > 0,
            }}
            render={({ field }) => (
              <GroupsUserInput {...field} onUserFromOutside={() => {}} />
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="startDate"
            control={control}
            rules={{
              validate: (value) =>
                value !== null &&
                value.isValid &&
                value.diffNow('days').days > -1,
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  disablePast
                  slotProps={{
                    textField: { variant: 'standard' },
                  }}
                  label={t(
                    'fileList.fileDetails.inviteUsersModal.form.accessGrant'
                  )}
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="expirationDate"
            control={control}
            rules={{
              validate: (value) =>
                value === null ||
                (value.isValid && startDate.diff(value, 'days').days < 0),
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  minDate={startDate}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      helperText: t(
                        'fileList.fileDetails.inviteUsersModal.form.accessExpiration.message'
                      ),
                    },
                  }}
                  label={
                    <p>
                      <Trans i18nKey="fileList.fileDetails.inviteUsersModal.form.accessExpiration.label">
                        Expiration date
                        <span className="text-gray-400">(optional)</span>
                      </Trans>
                    </p>
                  }
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <Typography className="!mb-sm !text-lg">
          {t('fileList.fileDetails.inviteUsersModal.form.generalAccess.label')}
        </Typography>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="access"
            control={control}
            render={({ field }) => (
              <Select
                variant="standard"
                data-testid="access-select"
                {...field}
                onChange={handleSelectPermission}
                multiple
                renderValue={(selected) =>
                  options
                    .filter((option) => selected.includes(option.value))
                    .map((option) => option.text)
                    .join(', ')
                }
              >
                {options.map((option) => (
                  <MenuItem
                    value={option.value}
                    className="!py-0"
                    key={option.value}
                  >
                    <Checkbox checked={access.includes(option.value)} />
                    <ListItemText primary={option.text} />
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </div>
      <Button
        variant="contained"
        color="primary"
        className="!mt-md float-right self-end"
        type="submit"
        disabled={!formState.isValid || !isAvailable}
      >
        {t('fileList.fileDetails.inviteUsersModal.form.continue')}
      </Button>
    </form>
  );
};

export default ShareFileForm;
