import { useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren, ReactNode } from 'react';

import useOrganization from '@pages/organizations/hooks/useOrganization';

interface OrganizationAclProps {
  /** Component to be rendered if user is not in an organization */
  fallback?: ReactNode;
}

const OrganizationAcl = ({
  children,
  fallback = null,
}: PropsWithChildren<OrganizationAclProps>) => {
  const { user } = useAuth0();
  const { data: organization } = useOrganization(user?.sub);

  if (organization) {
    return children;
  }

  return fallback;
};

export default OrganizationAcl;
