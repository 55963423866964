import { useQuery } from '@tanstack/react-query';

import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

const useFileDetails = (fileId: string) => {
  const { getFileDetails } = useFilesApi();

  const query = useQuery({
    queryFn: () => getFileDetails(fileId),
    queryKey: ['file-details', fileId],
    select: ({ data }) => fileResponseToFile(data),
  });

  return query;
};

export default useFileDetails;
