import {
  GetTokenSilentlyOptions,
  MissingRefreshTokenError,
} from '@auth0/auth0-react';
import axios, { AxiosError, AxiosInstance, HttpStatusCode } from 'axios';

import RequestError from '@app/errors/request';
import { configs } from '@filot/auth';
import { Range, UnsuccessfulResponse } from '@filot/types/api';

import { isRunningOnElectron } from './environment';

let instance: AxiosInstance | null = null;

export const getInstance = () => {
  if (instance) {
    return instance;
  }

  instance = axios.create();
  instance.interceptors.response.use(
    (response) => response,
    (err: AxiosError<UnsuccessfulResponse<unknown>>) => {
      if (err instanceof AxiosError) {
        if (err.response?.status === HttpStatusCode.Unauthorized) {
          if (isRunningOnElectron()) {
            window.auth.resetTokens();
          }
        }
        if (err.response && err.response.status && err.response.status > 500) {
          return Promise.reject(err);
        }

        if (err.response) {
          return Promise.reject(
            new RequestError({
              description: err.response.data.message,
              code: err.code,
              message: err.response.data.message,
              response: err.response.data,
              status: err.response.status,
              originUrl: location.href,
            })
          );
        }

        return Promise.reject(
          new AxiosError(
            'Client Axios Error',
            err.code,
            err.config,
            err.request,
            err.response
          )
        );
      }
    }
  );

  return instance;
};

export const getRangedAccessToken = async (
  range: Range,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
) => {
  try {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: configs[range].audience,
        scope: 'offline_access',
      },
    });

    return accessToken;
  } catch (e) {
    if (e instanceof MissingRefreshTokenError) {
      return window.auth.getAccessToken(range);
    }
  }
};
