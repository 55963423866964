import { Chip, ChipProps, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { Tag } from '@app/services/files/types';

interface TagChipProps extends ChipProps {
  tag: Pick<Tag, 'backgroundColor' | 'textColor' | 'name'>;
}

const TagChip = ({ tag, ...props }: TagChipProps) => {
  const className = useMemo(
    () =>
      twMerge(
        '!h-auto !max-w-40 overflow-ellipsis !px-0 !py-1',
        props.className
      ),
    [props.className]
  );

  return (
    <Tooltip title={tag.name} enterDelay={1000} placement="top">
      <Chip
        color="default"
        label={tag.name}
        {...props}
        className={className}
        style={{
          backgroundColor: tag.backgroundColor,
          color: tag.textColor,
          ...props.style,
        }}
      />
    </Tooltip>
  );
};

export default TagChip;
