import { useAuth0 } from '@auth0/auth0-react';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from 'react-router-dom';

import { isRunningOnElectron } from '@app/utils/environment';
import GlobalAlert from '@components/global-alert';

import LoadLogin from './auth/load-login';
import Login from './auth/login';
import Prelogin from './auth/prelogin';
import Error from './error';
import FileVersions from './file-versions/file-versions';
import Files from './files/files';
import GroupAddFiles from './group-add-files/group-add-files';
import GroupBulkEditing from './group-bulk-editing/group-bulk-editing';
import GroupDetails from './group-details/group-details';
import GroupFiles from './group-files/group-files';
import GroupMembers from './group-members/group-members';
import Groups from './groups/groups';
import Layout from './layout/layout';
import LoginRedirect from './login-redirect/login-redirect';
import Organizations from './organizations/organizations';
import OrganizationsBulkInvite from './organizations-bulk-inviting/organizations-bulk-inviting';
import Profile from './profile/profile';
import TagDetails from './tag-details/tag-details';
import Tags from './tags/tags';
import Trash from './trash/trash';

const createAppRouter = (appRoutes: RouteObject[]) => {
  if (isRunningOnElectron()) {
    return createHashRouter(appRoutes);
  }

  return createBrowserRouter(appRoutes);
};

const sharedRoutes: RouteObject[] = [
  {
    path: '/login-redirect',
    element: <LoginRedirect />,
    errorElement: <Error />,
  },
  {
    path: '/load-login',
    element: <LoadLogin />,
    errorElement: <Error />,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <Error />,
  },
];

export const routes: RouteObject[] = [
  {
    path: '/',
    element: [<Layout key="layout" />, <GlobalAlert key="alert" />],
    children: [
      {
        path: 'organizations',
        element: <Organizations />,
      },
      {
        path: 'organizations/bulk-invite',
        element: <OrganizationsBulkInvite />,
      },
      {
        path: 'groups',
        element: <Groups />,
      },
      {
        path: 'groups/:groupId',
        element: <GroupDetails />,
      },
      {
        path: 'groups/:groupId/files',
        element: <GroupFiles />,
      },
      {
        path: 'groups/:groupId/files/bulk-edit',
        element: <GroupBulkEditing />,
      },
      {
        path: 'groups/:groupId/files/add',
        element: <GroupAddFiles />,
      },
      {
        path: 'groups/:groupId/members',
        element: <GroupMembers />,
      },
      {
        path: 'files',
        element: <Files />,
      },
      {
        path: 'files/*',
        element: <Files />,
      },
      {
        path: 'tags',
        element: <Tags />,
      },
      {
        path: 'tags/:tagId',
        element: <TagDetails />,
      },
      {
        path: 'file/:fileId/versions',
        element: <FileVersions />,
      },
      {
        path: 'trash',
        element: <Trash />,
      },
    ],
    errorElement: <Error />,
  },
  {
    path: '/profile',
    element: <Profile />,
    errorElement: <Error />,
  },
  {
    path: '*',
    element: <Error />,
  },
  ...sharedRoutes,
];

const MainAppRouter = () => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    const authRouter = createAppRouter([
      {
        path: '*',
        element: <Prelogin />,
        errorElement: <Error />,
      },
      ...sharedRoutes,
    ]);

    return <RouterProvider router={authRouter} />;
  }

  const router = createAppRouter(routes);

  return <RouterProvider router={router} />;
};

export default MainAppRouter;
