import { useQuery } from '@tanstack/react-query';

import {
  Invitation,
  InvitationResponse,
} from '@app/services/organizations/types';
import useOrganizationApi, {
  invitationResponseToInvitation,
} from '@app/services/organizations/useOrganizationApi';
import { SuccessfulResponse } from '@filot/types/api';

const useInvitation = () => {
  const { getInvites } = useOrganizationApi();

  const query = useQuery<
    SuccessfulResponse<InvitationResponse[]> | null,
    Error,
    Invitation | undefined
  >({
    queryFn: () => getInvites(),
    queryKey: ['organization-invitations'],
    refetchOnMount: true,
    select: (data) => {
      if (data && data.data.length > 0) {
        return invitationResponseToInvitation(data.data[0]);
      }

      return undefined;
    },
  });

  return query;
};

export default useInvitation;
