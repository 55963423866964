import { DateTime } from 'luxon';

import {
  Paginated,
  PaginationOptions,
  SuccessfulResponse,
} from '@filot/types/api';

import { FileAccessLevel } from '../files/types';

export enum PermissionLevel {
  MEMBER = 'MEMBER',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
  CLIENT = 'CLIENT',
}

export type Status = 'active' | 'inactive';

export interface AccessDates {
  startDate: DateTime;
  expirationDate: DateTime | null;
}

export interface File {
  id: string;
  name: string;
  permissions: Record<FileAccessLevel, boolean>;
  type?: string;
}

export interface NewGroup {
  name: string;
  startDate: DateTime;
  expirationDate: DateTime | null;
}

export type UserRoles = Record<string, PermissionLevel>;

export type Group = {
  id: string;
  name: string;
  description: string | null;
  picture?: string;
  users: UserRoles;
  totalFiles: number;
  startDate: string;
  expirationDate?: string;
  permission: PermissionLevel;
  status: Status;
};

export type GroupResponse = {
  name: string;
  description: string;
  icon_image: string;
  users: Partial<UserRoles>;
  total_files: number;
  start_date: string;
  expiration_date?: string;
  id: string;
  status: Status;
};

export interface NewGroupRequest {
  name: string;
  startDate: string;
  expirationDate: string | null;
}

export interface Member {
  id: string;
  name: string;
  email: string;
  permissionLevel: PermissionLevel;
}

export interface NewGroupOwner {
  email: string;
}

export interface GroupMember {
  /** ISO date */
  created_at: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  nickname: string;
  picture: string;
  updated_at: string;
  user_id: string;
  last_login: string;
  role: PermissionLevel;
}

export interface GroupsApi {
  get: (
    paginationOptions: PaginationOptions,
    userId?: string
  ) => Promise<Paginated<GroupResponse>>;
  getDetails: (id: string) => Promise<GroupResponse>;
  updateFilePermissions: (options: {
    groupId: string;
    fileId: string;
    newPermissions: FileAccessLevel[];
  }) => Promise<void>;
  removeFile: (options: { groupId: string; fileId: string }) => Promise<void>;
  createGroup: (form: NewGroupRequest) => Promise<Group>;
  getFiles: (options: {
    groupId: string;
    page: number;
  }) => Promise<SuccessfulResponse<string[]>>;
  bulkEdit: (options: {
    groupId: string;
    fileIds: string[];
    permissions: FileAccessLevel[];
  }) => Promise<void>;
  getMembers: (groupId: string) => Promise<SuccessfulResponse<GroupMember[]>>;
  changeMemberPermission: (params: {
    member: string;
    groupId: string;
    permission: PermissionLevel;
  }) => Promise<void>;
  userHasAccount: (email: string) => Promise<boolean>;
  inviteUsers: (params: { emails: string[]; groupId: string }) => Promise<void>;
  removeUser: (params: { id: string; groupId: string }) => Promise<void>;
  addFiles: (options: { files: string[]; groupId: string }) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<void>;
  getGroupsAdmin: () => Promise<Group[]>;
  updateGroupDetails: (options: {
    groupId: string;
    name?: string;
    description?: string;
  }) => Promise<void>;
  reactivateGroup: (params: {
    groupId: string;
    startDate: string;
    expirationDate: string | null;
    restorePermissions: boolean;
  }) => Promise<void>;
  leaveGroup: (params: { newOwnerEmail?: string; id: string }) => Promise<void>;
  updateGroupPicture: (params: {
    file: globalThis.File;
    groupId: string;
  }) => Promise<void>;
}
