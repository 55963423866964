import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAppJoyride from '@app/hooks/useAppJoyride';
import { File, Tag } from '@app/services/files/types';
import { TagWithFiles } from '@app/services/tags/types';
import TablePagination from '@components/table-pagination';

import TagListItem from './tag-list-item';

interface TagsListViewProps {
  tags: TagWithFiles[];
  onFileClick: (file: File) => void;
  onOpenMergeTag?: (tag: Tag) => void;
  page: number;
  onPageChange: (newPage: number) => void;
  total: number;
  isPlaceholderData?: boolean;
}

const TagsListView = ({
  tags,
  onFileClick,
  onOpenMergeTag,
  page,
  onPageChange,
  total,
  isPlaceholderData,
}: TagsListViewProps) => {
  const [expanded, setExpanded] = useState<string | false>(
    tags.at(0)?.id ?? false
  );
  const navigate = useNavigate();
  const { updateStageConfig } = useAppJoyride();
  updateStageConfig(6, 3, {
    onNext: () => {
      if (tags.at(0)) {
        navigate(`/tags/${tags.at(0)!.id}`, { state: { tag: tags.at(0) } });
      }
    },
  });

  const handleChange =
    (panel: string) => (_: unknown, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div className="mt-lg flex flex-col shadow-md">
      {tags.map((tag) => (
        <TagListItem
          key={tag.id}
          tag={tag}
          files={tag.files}
          expanded={expanded === tag.id}
          onChange={handleChange(tag.id)}
          onFileClick={onFileClick}
          onOpenMergeTag={onOpenMergeTag}
        />
      ))}
      <table>
        <tfoot>
          <TablePagination
            onPageChange={onPageChange}
            rowsPerPage={5}
            page={page}
            total={total}
            isPlaceholderData={isPlaceholderData}
          />
        </tfoot>
      </table>
    </div>
  );
};

export default TagsListView;
