import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useContextMenu from '@app/hooks/useContextMenu';
import { File } from '@app/services/files/types';
import TrashIcon from '@assets/shared/trash.svg?react';

import MoveToTrashAlertModal from './move-to-trash-alert-modal';

interface FolderCardContextMenuProps {
  anchorElement: HTMLElement | null;
  folder: File;
}

const ICON_SIZE = 16;

const FolderCardContextMenu = ({
  folder,
  anchorElement,
}: FolderCardContextMenuProps) => {
  const { t } = useTranslation();
  const [isMoveToTrashAlertVisible, setIsMoveToTrashAlertVisible] =
    useState(false);

  const { ContextMenu } = useContextMenu(anchorElement, {
    items: [
      {
        /* v8 ignore next */
        onClick: () => setIsMoveToTrashAlertVisible(true),
        text: t('folderCardContextMenu.moveToTrash'),
        icon: (
          <TrashIcon
            className="text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        ),
        disabled: folder.fileName === 'Shared',
      },
    ],
  });

  return (
    <>
      <ContextMenu />
      <MoveToTrashAlertModal
        isOpen={isMoveToTrashAlertVisible}
        /* v8 ignore next */
        onClose={() => setIsMoveToTrashAlertVisible(false)}
        folder={folder}
      />
    </>
  );
};

export default FolderCardContextMenu;
