import { Typography } from '@mui/material';
import { ChangeEventHandler, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useUserPermissions from '@app/hooks/useUserPermissions';
import UploadIcon from '@assets/files/upload.svg?react';
import ButtonBase from '@components/button-base';

import { FileDetailsContext } from './file-details';
import UpdateFileModal from './update-file-modal';

const ICON_SIZE = 16;

const FileDetailsUpdateFileButton = () => {
  const { t } = useTranslation();
  const fileDetails = useContext(FileDetailsContext);
  const [isUpdateFileModalOpen, setIsUpdateFileModalOpen] = useState(false);
  const [documentToUpload, setDocumentToUpload] = useState<File | null>(null);
  const fileUpdate = useRef<HTMLInputElement>(null);
  const { displayGlobal } = useAlert();

  const permissions = useUserPermissions(fileDetails!);

  const handleUpdateFile = () => {
    if (fileDetails?.locked === false) {
      displayGlobal({
        text: t('fileList.fileDetails.buttons.upload.alerts.mustLock'),
        type: 'error',
      });

      return;
    }
    if (fileUpdate.current) {
      fileUpdate.current.value = '';
    }
    document.getElementById('file-update')?.click();
  };

  const handleOpenFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      setIsUpdateFileModalOpen(true);
      [...event.target.files].forEach((file) => {
        setDocumentToUpload(file);
      });
    }
  };

  return (
    <>
      <input
        type="file"
        id="file-update"
        onChange={handleOpenFile}
        ref={fileUpdate}
        className="hidden"
        data-testid="file-update"
      />
      <ButtonBase
        focusRipple
        onClick={handleUpdateFile}
        disabled={!permissions.write}
        className="!p-sm !justify-start"
      >
        <UploadIcon className="mr-sm" width={ICON_SIZE} height={ICON_SIZE} />
        <Typography>
          {t('fileList.fileDetails.buttons.upload.button')}
        </Typography>
      </ButtonBase>
      <UpdateFileModal
        isOpen={isUpdateFileModalOpen}
        onClose={() => setIsUpdateFileModalOpen(false)}
        document={documentToUpload}
      />
    </>
  );
};

export default FileDetailsUpdateFileButton;
