import { Env } from '@filot/types/env';

export const getEnv = (): Env => ({
  mode: import.meta.env.MODE,
  mockApiCalls: import.meta.env.RENDERER_VITE_MOCK_API_CALLS === 'true',
  auth0: {
    domain: import.meta.env.RENDERER_VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.RENDERER_VITE_AUTH0_CLIENT_ID,
  },
  filot: {
    organizations: {
      domain: import.meta.env.RENDERER_VITE_FILOT_ORGANIZATION_DOMAIN,
    },
    notes: {
      domain: import.meta.env.RENDERER_VITE_FILOT_NOTES_DOMAIN,
    },
    groups: {
      domain: import.meta.env.RENDERER_VITE_FILOT_GROUPS_DOMAIN,
    },
    files: {
      domain: import.meta.env.RENDERER_VITE_FILOT_FILES_DOMAIN,
    },
    users: {
      domain: import.meta.env.RENDERER_VITE_FILOT_USERS_DOMAIN,
    },
    tags: {
      domain: import.meta.env.RENDERER_VITE_FILOT_TAGS_DOMAIN,
    },
  },
  variables: {
    loginUrl: import.meta.env.RENDERER_VITE_LOGIN_URL,
  },
});
