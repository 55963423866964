import { ButtonBaseProps, ButtonBase as MuiButtonBase } from '@mui/material';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

const ButtonBase = (props: ButtonBaseProps) => {
  const className = useMemo(
    () => twMerge(props.className, props.disabled ? '!text-gray-400' : ''),
    [props.className, props.disabled]
  );

  return <MuiButtonBase {...props} className={className} />;
};

export default ButtonBase;
