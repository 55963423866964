import { Box, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import { File } from '@app/services/files/types';
import ShareErrorIcon from '@assets/files/share-error.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';
import StopClickPropagation from '@components/stop-click-propagation';

import useSharedFile from '../hooks/useSharedFiles';

import { FileDetailsContext } from './file-details';
import ManageAccessForm from './manage-access-form';
import ManageAccessGeneralAccess from './manage-access-general-access';
import ManageAccessPeople from './manage-access-people';

interface ManageAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: File | null;
  ownerId: string;
}

const ManageAccessModal = ({
  onClose,
  isOpen,
  file,
  ownerId,
}: ManageAccessModalProps) => {
  const { t } = useTranslation();
  const { isLoading, isError, data, refetch } = useSharedFile(
    file?.id,
    file?.type
  );

  const withCanClose = useCloseRequestModal(isLoading);
  const [emails, setEmails] = useState<string[]>([]);

  const handleShare = (email: string) => {
    if (!emails.includes(email)) {
      setEmails((prevEmails) => [...prevEmails, email]);
    }
  };

  const Content = () => {
    if (isLoading) {
      return (
        <div className="flex h-56 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="p-screen flex flex-1 flex-col items-center justify-center">
          <ShareErrorIcon width={180} height={180} />
          <p className="mb-md text-center text-xl font-medium">
            <Trans i18nKey="fileList.fileDetails.manageAccessModal.error">
              Access management could not be loaded
              <span
                className="cursor-pointer underline"
                onClick={() => refetch()}
              >
                Try Again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    if (data) {
      return (
        <FileDetailsContext.Provider value={file}>
          <ManageAccessForm onEmailType={handleShare} />
          <ManageAccessPeople
            emails={emails}
            users={data.users_with_access}
            ownerId={ownerId}
          />
          <ManageAccessGeneralAccess />
        </FileDetailsContext.Provider>
      );
    }
  };

  if (!file) {
    return null;
  }

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <StopClickPropagation>
        <Box
          className="w-medium-modal !py-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md"
          id="manage-access-modal"
        >
          <PressableIcon
            onClick={withCanClose(onClose)}
            icon={<Close title="close" />}
            className="!mr-screen !absolute self-end"
          />
          <Typography className="!pl-screen !text-xl !font-medium">
            {t('fileList.fileDetails.manageAccessModal.title', {
              fileName: file.fileName,
            })}
          </Typography>
          <Content />
        </Box>
      </StopClickPropagation>
    </Modal>
  );
};

export default ManageAccessModal;
