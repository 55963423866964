import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useUpdateFile = () => {
  const { updateContent } = useFilesApi();
  const mutation = useMutation({
    mutationFn: updateContent,
    mutationKey: ['file-update-content'],
  });

  return mutation;
};

export default useUpdateFile;
