import { Tag } from '@app/services/files/types';
import { stringToColor } from '@app/utils/colors';

const useCreateTag = () => {
  const createTag = (name: string): Tag => {
    const colors = stringToColor(name, { opacity: 0.7 });

    return {
      backgroundColor: colors.color,
      description: null,
      id: name,
      name,
      textColor: colors.contrastColor,
    };
  };

  return createTag;
};

export default useCreateTag;
