import { Auth0Provider, ICache } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';
import { useMemo } from 'react';

import { AlertProvider } from '@app/hooks/useAlert';
import { AppNavigateContextProvider } from '@app/hooks/useAppNavigate';
import { BreadcrumbContextProvider } from '@app/hooks/useBreadcrumb';
import { DraggingFileContextProvider } from '@app/hooks/useDraggingFile';
import { SearchContextProvider } from '@app/hooks/useSearch';
import * as Storage from '@app/utils/storage';
import PostLogin from '@components/post-login';
import { UserInputChipContextProvider } from '@pages/group-members/hooks/useUserInputContext';
import MainAppRouter from '@pages/routes';

import { getEnv } from './config/env';
import { lightModeTheme } from './config/theme';
import RequestError from './errors/request';
import { AppJoyrideProvider } from './hooks/useAppJoyride';
import { LockedFilesProvider } from './hooks/useLockedFiles';
import { isRunningOnElectron } from './utils/environment';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 60 * 1000,
      retry: (failureCount, error) => {
        if (
          error instanceof RequestError &&
          error.status === HttpStatusCode.Unauthorized
        ) {
          return false;
        }

        return failureCount < 3;
      },
      throwOnError: (error) =>
        error instanceof RequestError &&
        error.status === HttpStatusCode.Unauthorized,
    },
  },
});
const { auth0 } = getEnv();

const App = () => {
  const cache = useMemo<ICache>(
    () => ({
      get: async (key) => {
        const item = await Storage.getItem(key);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return item ? JSON.parse(item) : undefined;
      },
      remove: (key) => Storage.deleteItem(key),
      set: async (key, entry) => {
        await Storage.setItem(key, JSON.stringify(entry));
      },
    }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Auth0Provider
        domain={auth0.domain}
        clientId={auth0.clientId}
        useRefreshTokens
        useRefreshTokensFallback={!isRunningOnElectron()}
        cache={cache}
        cookieDomain=".filot.io"
      >
        <PostLogin />
        <ThemeProvider theme={lightModeTheme}>
          <BreadcrumbContextProvider>
            <SearchContextProvider>
              <AppNavigateContextProvider>
                <UserInputChipContextProvider>
                  <DraggingFileContextProvider>
                    <AlertProvider>
                      <AppJoyrideProvider>
                        <LockedFilesProvider>
                          <MainAppRouter />
                        </LockedFilesProvider>
                      </AppJoyrideProvider>
                    </AlertProvider>
                  </DraggingFileContextProvider>
                </UserInputChipContextProvider>
              </AppNavigateContextProvider>
            </SearchContextProvider>
          </BreadcrumbContextProvider>
        </ThemeProvider>
      </Auth0Provider>
    </QueryClientProvider>
  );
};

export default App;
