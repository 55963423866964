import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useSharedFile = (
  sharedFileId: string | undefined,
  sharedFileType: string = 'files'
) => {
  const { getSharedFile } = useFilesApi();

  const query = useQuery({
    queryFn: () => getSharedFile(sharedFileId!, sharedFileType),
    queryKey: ['files-shared', sharedFileId, sharedFileType],
    placeholderData: keepPreviousData,
    enabled: !!sharedFileId && !!sharedFileType,
    select: ({ data }) => data,
  });

  return query;
};

export default useSharedFile;
