import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useUserPermissions from '@app/hooks/useUserPermissions';
import LockFileIcon from '@assets/files/lock.svg?react';
import UnlockFileIcon from '@assets/files/unlock.svg?react';
import ButtonBase from '@components/button-base';

import useLockFile from '../hooks/useLockFile';

import ExtendLockButton from './extend-lock-button';
import { FileDetailsContext } from './file-details';
import LockRemainingTime from './lock-remaining-time';

const ICON_SIZE = 16;

const FileDetailsLockButton = () => {
  const file = useContext(FileDetailsContext);
  const { t } = useTranslation();
  const { displayGlobal } = useAlert();
  const { isPending, mutateAsync: lockFile } = useLockFile();
  const { user } = useAuth0();
  const permissions = useUserPermissions(file!);
  const queryClient = useQueryClient();

  if (!file) {
    return null;
  }

  const handleLockFile = async () => {
    if (isPending || !permissions.write) {
      return;
    }
    if (file.locked && file.lock.lockedBy !== user?.sub) {
      displayGlobal({
        text: t('fileList.fileDetails.buttons.upload.alerts.locked'),
        type: 'error',
      });

      return;
    }
    try {
      if (file) {
        await lockFile({ fileId: file.id, lock: !file.locked });
        await queryClient.invalidateQueries({
          queryKey: ['file-details', file.id],
        });
      }
    } catch (e) {
      displayGlobal({
        text: t('fileList.fileDetails.buttons.lock.error', {
          context: file.locked ? 'unlocking' : 'locking',
        }),
        type: 'error',
      });
    }
  };

  return (
    <div className="gap-x-sm flex flex-1 items-center">
      <ButtonBase
        focusRipple
        onClick={handleLockFile}
        disabled={!permissions.write}
        className="!p-sm !flex-1 !justify-start"
      >
        {file.locked ? (
          <UnlockFileIcon
            className="mr-sm"
            width={ICON_SIZE}
            height={ICON_SIZE}
            title="unlock"
          />
        ) : (
          <LockFileIcon
            className="mr-sm"
            width={ICON_SIZE}
            height={ICON_SIZE}
            title="lock"
          />
        )}
        <Typography>
          {file.locked
            ? t('fileList.fileDetails.buttons.lock.unlockFile')
            : t('fileList.fileDetails.buttons.lock.lockFile')}
        </Typography>
      </ButtonBase>
      {file.lock ? (
        <>
          <LockRemainingTime lock={file.lock} />
          <ExtendLockButton lock={file.lock} />
        </>
      ) : null}
    </div>
  );
};

export default FileDetailsLockButton;
