import { getEnv } from '@app/config/env';
import RequestError from '@app/errors/request';
import { getRangedAccessToken, getInstance } from '@app/utils/http-instance';
import { ServiceOptions, SuccessfulResponse } from '@filot/types/api';

import { UsersApi, UserResponse } from './types';

const getUsersService = ({
  url,
  getAccessTokenSilently,
}: ServiceOptions): UsersApi => {
  const {
    filot: { users },
  } = getEnv();

  return {
    get: async (id) => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<UserResponse>>(
        `${url ?? users.domain}/api/v1/users/${id}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    getUserByEmail: async (email) => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );
      const instance = getInstance();

      try {
        const { data } = await instance.get<SuccessfulResponse<UserResponse>>(
          `${url ?? users.domain}/api/v1/users/email/${email}/get`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        return data;
      } catch (e) {
        if (
          e instanceof RequestError &&
          e.getResponseError().message === 'User not found!'
        ) {
          return {
            message: 'User not found!',
            success: true,
            data: undefined,
          };
        }

        throw e;
      }
    },

    update: async ({ name, phoneNumber, id }) => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? users.domain}/api/v1/users/${id}/modify`,
        {
          name,
          user_metadata: {
            phone_number: phoneNumber,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    postLogin: async (userId) => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? users.domain}/api/v1/users/${userId}/post_login`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },
  };
};

export default getUsersService;
