/* eslint-disable max-len */
export default {
  title: 'Filot',
  formats: {
    date: {
      simpleDateWithTime: 'LL-dd-yyyy hh:mm',
    },
  },
  prelogin: {
    text: 'The <1>PILOT</1> for your <3>FILES</3>',
    getStarted: 'GET STARTED NOW',
  },
  loginRedirect: {
    loading: {
      title: 'Loading app environment... Please wait a few moments.',
    },
    title: 'Login succeed!',
    text: 'You will be redirected to the app in a few seconds. If this does not happen, click the button.',
    button: 'Open app',
  },
  login: {
    title: 'Redirecting to login page...',
  },
  joyride: {
    buttons: {
      next: 'Next',
      skip: 'Skip',
      back: 'Back',
    },
    dashboard: {
      step_1:
        "On the left is your dashboard, here you'll see a navigation panel with your home page, recent, tags, and favorites!",
      step_2:
        "In the main area, you'll see your documents and folders. Let's take a closer look.",
    },
    documents: {
      step_1: 'Click on a folder to view its contents.',
      step_2:
        "You'll see a list of documents and folders, these are held within that folder.",
    },
    folders: {
      step_1:
        'Navigate back to the homepage by clicking on the name "Filot" in the middle left of your screen',
      step_2:
        'Create a new folder by left clicking in any open space in the main area',
      step_3: 'Name this folder "Walkthrough Folder"',
    },
    organizeDocuments: {
      step_1: 'You are also able to move documents.',
      step_2: 'Click on a document and drag it into the "Walkthrough Folder"',
    },
    previewDocuments: {
      step_1: 'Click on a document to preview it.',
      step_2: 'You can preview the file, by clicking the "Open preview" button',
      step_3:
        'Within the preview you can: download your file, view its versions history and synchronize with cloud',
      step_4:
        'You can easily download documents from the file preview by clicking download file',
      step_5:
        'While previewing a document, also notice the "Notes" section on the right.',
      step_6:
        'Click "New Note" to leave a comment or instructions for your lawyer. Your lawyer can also leave notes here for you.',
    },
    versions: {
      step_1:
        'While previewing a document, notice the document version section.',
      step_2:
        'Here, you can see all previous versions of the document. Click on a version to view it or restore it if needed.',
    },
    tags: {
      step_1: 'Also within the preview, you are able to tag documents.',
      step_2:
        'Add the tag "walkthrough". Tags help you categorize and find documents easily.',
      step_3: 'Back on the dashboard, click the "Tags" button.',
      step_4: 'Click on a tag to see all documents associated with that tag.',
      step_5:
        'This helps you quickly find all related documents without having to search through folders.',
    },
    groups: {
      step_1:
        'If your lawyer shares documents with you, they will appear as a "Group Folder".',
      step_2:
        'You can view, download, and add notes to these documents, but you cannot share them further.',
      step_3: 'You can also access "Groups" from your dashboard.',
      step_4:
        'Navigate back to the homepage by clicking on the Filot logo on the top left of your screen',
    },
    conclusion: {
      step_1:
        "You've now explored the main features of Filot's web application.",
      step_2:
        'If you need help or support, click the "Help" button at the top-right corner of the dashboard.',
      step_3:
        'Thank you for using Filot! Enjoy the ease and security of managing your legal documents online.',
    },
  },
  components: {
    tablePagination: {
      rows: 'Rows per page',
      records: '{{first}}-{{last}} of {{total}}',
    },
    usersInputEmailChip: {
      userFromOutside: 'This user is not in your organization',
      userWithoutAccount: 'This user does not have an account',
    },
    groupsUserInput: {
      label: 'Users or group',
      message: "Enter user's e-mail or group's name",
    },
    editableText: {
      save: 'Save changes',
      discard: 'Discard changes',
    },
    updateAvatar: {
      change: 'Change picture',
    },
    errorHelpReport: {
      report: 'Report issue',
    },
    helpDesk: {
      contactUs: {
        title: 'Contact Us',
        reportABug: 'Report a bug',
        requestNewFeature: 'Request new feature',
      },
      howTo: {
        title: 'How to',
        createFolders: 'Create folders',
        moveDocuments: 'Move documents',
        downloadAndAddNotesToDocuments: 'Download and add notes to documents',
        useVersioning: 'Use versioning',
        tagDocuments: 'Tag documents',
        shareDocuments: 'Share documents',
      },
    },
    infiniteLoadSelect: {
      loading: 'Loading more...',
    },
    tagInput: {
      placeholder: 'Type a tag, then press "Enter" to add it',
    },
  },
  layout: {
    header: {
      search: {
        placeholder: { default: 'Search in Filot', tags: 'Search in tags' },
        advancedSearch: 'Advanced search',
      },
      help: 'Help',
      advancedSearch: {
        type: 'Type',
        organizationName: 'Organization name',
        fileName: 'File name',
        tags: 'Tags',
        path: 'Path',
        modificationDate: 'Modification date',
        searchButton: 'SEARCH',
        options: {
          types: {
            any: 'Any type',
          },
          organizations: {
            any: 'Any organization',
          },
          paths: {
            any: 'Any path',
            trash: 'Trash',
            favorites: 'Favorites',
            other: 'Select other...',
          },
        },
        placeholders: {
          fileName: "Search for words in file's names",
          dates: {
            before: 'Before date',
            after: 'After date',
          },
        },
      },
      profileMenu: {
        profile: 'Profile',
        logout: 'Sign out',
      },
    },
    sidebar: {
      new: {
        button: 'NEW',
        menu: {
          upload: 'Upload file',
          newFolder: 'Create folder',
        },
      },
      home: 'Home',
      organizations: 'Organizations',
      groups: 'Groups',
      recent: 'Recent',
      tags: 'Tags',
      favorites: 'Favorites',
      trash: 'Trash',
      version: 'Version: {{version}}',
    },
    uploadPreview: {
      title: 'Upload a file',
      fileName: 'File name',
      tags: 'Tags',
      location: 'Location',
      upload: 'UPLOAD',
      noPreviewAvailable: 'No preview available for this file',
      placeholders: {
        fileName: 'File name is mandatory',
        tags: 'Tags help you organize your files',
      },
      success: 'File uploaded',
      fail: {
        title: 'Oops! There was an error uploading your file',
        text: '<0>Please </0><1>try again</1><2>.</2>',
        tags: 'There was an error creating tags. Please try to create them again',
      },
    },
  },
  fileList: {
    error: {
      title: 'Oops! There was an error loading files',
      text: '<0>Please </0><1>try again</1><2>.</2>',
    },
    filters: {
      options: {
        type: 'Type: ',
        organizationName: 'Organization: ',
        fileName: 'File name: ',
        path: 'In: ',
        after: 'After: ',
        before: 'Before: ',
        tags: 'Tags: ',
      },
    },
    empty: {
      default: 'Drag your files here or use the “Upload” button',
      drop: 'Drop files to upload',
    },
    sortOptions: {
      name: 'Name',
      type: 'Type',
      creation: 'Creation date',
      organization: 'Organization',
    },
    searchResults: {
      lastModified: 'Last modified: {{date}}',
      noResults: {
        title: 'No results found!',
        text: 'Try another search',
      },
    },
    fileDetails: {
      error: {
        title: "Oops! We could not load this file's details",
        tryAgain: 'Try again?',
      },
      buttons: {
        preview: 'Open preview',
        download: 'Download file',
        history: 'View history',
        cloudSync: 'Sync with cloud',
        upload: {
          button: 'Upload new version',
          modal: {
            title: '{{fileName}} - New version',
            update: 'Upload new version',
            success: 'File successfully updated!',
            error:
              'Oops! There was an error uploading the new version of the file.',
          },
          alerts: {
            mustLock: 'You must lock the file before editing it',
            locked: "File is locked. It's not possible to edit it now",
          },
        },
        lock: {
          lockFile: 'Lock file',
          unlockFile: 'Unlock file',
          error_locking: 'There was an error locking file',
          error_unlocking: 'There was an error unlocking file',
          remainingTime: 'Remaining locked time: {{time}}',
          notification: {
            title: "Your file's lock is about to expire",
            body: 'Hi there! Just a heads-up — the lock for file "{{file}}" will expire in 1 hour and 30 minutes. If you still need it, consider extending the lock.',
          },
          extend: {
            button: 'Increase lock for 30 minutes',
            error: "There was an error extending file's lock. Please try again",
            success: "File's lock extended successfully",
          },
        },
      },
      users: {
        peopleWithAccess: 'People with Access',
        manageAccess: 'Manage access',
      },
      versions: {
        title: 'Version',
        description: 'From {{date}} | Accepted by: {{name}}',
        manageVersions: 'Manage versions',
      },
      info: {
        title: 'File details',
        type: 'Type',
        tags: 'Tags',
        size: 'Size',
        owner: 'Owner',
        location: 'Location',
      },
      tags: {
        noTags: 'Add tags to this file',
      },
      deleteTags: {
        errorAlert: 'Error removing tag. Please try again',
      },
      tagsEditorModal: {
        title: "{{document.fileName}}'s tags",
        add: 'Done',
        error: 'Error updating tags. Please try again later',
      },
      inviteUsersModal: {
        title: 'Share with...',
        share: 'Share',
        createGroup: 'Share with new group',
        error: 'Error sharing file. Please try again later',
        form: {
          accessGrant: 'Access grant',
          accessExpiration: {
            label: 'Access expiration <1>(optional)</1>',
            message: 'Leave it blank if you want the access to never expiry',
          },
          generalAccess: {
            label: 'General access',
            viewer: 'View',
            editor: 'Edit',
            updatePermissions: 'Manage permissions',
            delete: 'Delete file',
            createNotes: 'Create notes',
            readNotes: 'View notes',
            revokeAccess: 'Revoke access',
          },
          continue: 'Continue',
        },
      },
      shareFileConfirmation: {
        title: 'Sharing file...',
        period_expiration: '{{grant}} - {{expiration}}',
        period: 'Starting {{grant}}',
        back: 'Back',
        share: 'Share',
      },
      manageAccessModal: {
        title: 'Share "{{fileName}}"',
        error: 'Access management could not be loaded. <1>Try Again?</1>',
        form: {
          label: 'Add people, groups or organizations',
          message: 'Type an email, then press enter',
          people: {
            title: 'People with access',
            youUser: '{{name}} (you)',
          },
          generalAccess: {
            title: 'General access',
          },
        },
      },
      notes: {
        title: 'Notes',
        error: 'Notes could not be loaded. <1>Try Again?</1>',
        add: 'New note',
        form: {
          placeholder: 'Press shift + enter to enter a new line',
          label: 'New note',
          done: 'Save',
          error: 'Note could not be created! Please <1>Try again</1>',
        },
        card: {
          header: 'Created by {{name}} in {{date}}',
          delete: 'Delete note',
          edit: {
            button: 'Edit note',
            label: 'Edit note',
            save: 'Save',
            error: 'There was an error updating note. Please try again.',
            cancel: 'Cancel',
          },
          errorDeleting: 'There was an error deleting note. Please try again.',
        },
      },
    },
    folderDetails: {
      error: {
        title: "Oops! We could not load this folder's details",
        tryAgain: 'Try again?',
      },
      info: {
        title: 'Folder details',
        size: 'Size',
        owner: 'Owner',
        location: 'Location',
      },
    },
    fileListContextMenu: {
      createFolder: 'Create folder',
      newFolderModal: {
        title: 'New folder',
        placeholder: 'Folder name',
        submit: 'Create',
        error: 'Oops! Folder not created. Try again',
      },
    },
    filePreviewModal: {
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
      download: 'Download',
      sync: 'Sync with cloud',
      manageVersions: 'Manage versions',
      edit: {
        title: 'Open file in default editor',
        error: 'Error opening file. Please, try again',
      },
      error: 'Oops! We could not load the file. <1>Try again?</1>',
    },
  },
  fileCardContextMenu: {
    rename: 'Rename',
    moveToFolder: 'Move to',
    syncWithCloud: 'Sync with Cloud',
    download: 'Download',
    moveToTrash: 'Move to trash',
    renameFileModal: {
      title: 'Rename',
      placeholder: 'Type a new name',
      submit: 'Done',
      error: 'There was an error renaming the file. Please try again',
    },
    moveToModal: {
      title: 'Move to...',
      submit: 'Move',
      error: 'There was an error moving the file. Please try again',
    },
    syncFiles: {
      syncing: 'Synchronizing file...',
      error: 'There was an error synchronizing the file. Please try again',
      success: 'File successfully synchronized',
    },
    downloadFile: {
      error: 'There was an error downloading the file. Please try again',
    },
    moveToTrashModal: {
      title: 'Move to trash?',
      disclaimer:
        '"{{fileName}}" will be deleted forever after 30 days. Groups with access can still view and make copies of files until they\'re permanently deleted.',
      error: 'There was an error moving file to trash. Please, try again',
      cancel: 'Cancel',
      move: 'Move to trash',
    },
    preview: 'Open preview',
    edit: 'Edit',
    manageVersions: 'Manage versions',
  },
  folderCardContextMenu: {
    moveToTrash: 'Move to trash',
    moveToTrashModal: {
      title: 'Move to trash?',
      disclaimer:
        '"{{fileName}}" will be deleted forever after 30 days. Groups with access can still view and make copies of files until they\'re permanently deleted.',
      error: 'There was an error moving folder to trash. Please, try again',
      cancel: 'Cancel',
      move: 'Move to trash',
    },
  },
  organization: {
    tabs: {
      overview: 'OVERVIEW',
      users: 'USERS',
      groups: 'GROUPS',
      settings: 'SETTINGS',
    },
    invitation: {
      text: 'You were invited to join organization "{{organizationName}}", accept to share files, view groups and much more!',
      accept:
        "Please verify with your organization's IT staff that this is the expected organization, then accept the invitation. It will expire {{relative}}.",
      button: 'accept invitation',
      modal: {
        title: 'Join organization',
        text: 'You are about to join organization "{{organizationName}}"',
        continue: 'Continue',
        error:
          'Oops! There was an error accepting invitation. <1>Try again?</1>',
      },
    },
    overview: {
      basicInformation: {
        title: 'Basic Information',
        name: 'Name',
        description: 'Description',
        save: 'Save changes',
        error: 'Error updating organization. Please, try again',
      },
      dangerZone: {
        title: 'Danger zone',
        transferOwnership: {
          title: 'Transfer ownership',
          description:
            'You will lose all of the permissions you currently have and the new owner will have total access to the organization management. This action cannot be undone.',
          button: 'Transfer ownership',
          transferWarningModal: {
            title: 'Transfer organization ownership',
            description:
              'Please select the new member in the list of organization members.',
            label: 'New owner',
            cancel: 'cancel',
            transfer: 'transfer',
            success: 'Organization ownership transferred successfully!',
            error:
              'Oops! There was an error transferring the organization ownership. Please, <1>try again</1>.',
          },
        },
      },
    },
    users: {
      error:
        "Oops! We could not load your organization's users. <1>Try again?</1>",
      invite: 'Invite users',
      table: {
        header: {
          name: 'Name',
          email: 'E-mail',
          lastLogin: 'Last Login',
          status: 'Status',
        },
        status: {
          pending: 'Invite sent',
          active: 'Active',
        },
        contextMenu: {
          removeUser: 'Remove from organization',
        },
        removeUserModal: {
          title: 'Removing user...',
          newOwner: {
            label: "New owner's e-mail",
            message:
              "Once someone's access is removed, the documents they own will have their ownership transferred to this user",
          },
          accessExpiration: 'Access expiration',
          continue: 'Continue',
        },
        removeConfirmation: {
          title: 'Removing user...',
          text: 'Are you sure you want to remove {{name}} from organization? This action cannot be undone!',
          error:
            'Oops! There was an error removing the user from organization. <1>Try Again?</1>',
          success: 'Successfully scheduled user removal',
          cancel: 'Cancel',
          remove: 'Remove',
        },
      },
      inviteUsersModal: {
        title: 'Invite to group',
        invite: 'Invite',
        bulkInviting: 'Bulk invite',
        error: {
          gettingUserData: {
            title:
              'There was an error getting user data. Did you type the e-mail correctly?',
            tryAgain: 'Try again',
          },
          inviting: 'There was an error inviting user. Please <1>try again</1>',
        },
        form: {
          title: 'Invite users',
          email: 'E-mail',
          emails: "Users' e-mail <1>(comma-separated)</1>",
          accessGrant: 'Access grant',
          expiration: {
            label: 'Access expiration <1>(optional)</1>',
            message: 'Leave it blank if you want the access to never expiry',
          },
          review: 'Review',
        },
        review: {
          accessPeriod: 'Access period',
          period_expiration: '{{grant}} - {{expiration}}',
          period: 'Starting {{grant}}',
          back: 'Go back',
          invite: 'Invite',
        },
      },
      success: 'User invited successfully',
    },
    empty: {
      title: 'With <1>organizations</1> you can',
      list: "<0><1>Make files available for everybody in your organization</1><1>Manage user's access level</1><1>And much more!</1></2>",
      createNew: 'Create new organization',
    },
    newOrganization: {
      title: 'New organization',
      name: 'Organization name',
      domain: {
        label: 'Primary Organization Domain <1>(e.g. filot.io, google.com)</1>',
        message:
          'Should be the same as the domain extension on organization e-mail accounts.',
      },
      dns: {
        label: 'DNS record <1>(to verify domain ownership)</1>',
        message: 'Use that string to create a new record in your DNS system',
        creating: 'Generating DNS records',
        error: 'Error generating DNS record! Please <1>try again</1>',
        verify: {
          verify: 'Verify',
          notVerified: 'DNS is not verified',
          verified: 'DNS verified',
          creating: 'Verifying DNS records',
          error: 'Error verifying DNS record! Please <1>try again</1>',
        },
      },
      submit: 'Create organization',
      errorCreating:
        'There was an error creating organization. Please <1>try again</1>',
    },
    error: 'Oops! We could not load your organization. <1>Try again?</1>',
    bulkInvite: {
      title: 'Inviting users',
      form: {
        emails: "Users' e-mail <1>(comma-separated)</1>",
        accessGrant: 'Access grant',
        expiration: {
          label: 'Access expiration <1>(optional)</1>',
          message: 'Leave it blank if you want the access to never expiry',
        },
      },
      back: 'Go back',
      invite_one: 'Invite {{count}} person',
      invite_other: 'Invite {{count}} people',
      email_errors: {
        invalid: 'Invalid e-mail',
        duplicated:
          'This e-mail is duplicated. Please delete one of the copies',
      },
      success: 'Invites created successfully',
      error:
        'Oops! There was an error inviting users. Please <1>review the data and try again</1>',
    },
  },
  groups: {
    title: 'Groups',
    empty: {
      notSharing: 'You are not sharing any of your files',
      createGroup: 'Create group',
    },
    newGroupModal: {
      title: 'Share files with new group',
      name: 'Group name',
      users: {
        label: 'Users',
        message: "Enter user's e-mail",
      },
      startDate: 'Start date',
      expirationDate: {
        label: 'Expiration date <1>(optional)</1>',
        message: 'Leave it blank if you want the access to never expiry',
      },
      create: 'Create group',
      error:
        'Oops! There was an error creating the new group. Please <1>try again</1>.',
    },
    error: 'Oops! We could not load your groups. <1>Try again?</1>',
    table: {
      name: 'Name',
      users: 'Users',
      owner: 'Owner',
      status: {
        label: 'Status',
        active: 'Active',
        inactive: 'Inactive',
      },
    },
    contextMenu: {
      groupDetails: 'Group details',
      manageFiles: 'Manage files',
      manageMembers: 'Manage members',
    },
    groupDetails: {
      expires: 'This group expires {{relative}}',
      expired: 'This group expired',
      accessNotReached: 'This group will be activated on {{date}}',
      neverExpires: 'This group will never expire',
      files: { title: 'Files ({{total}})', manage: 'Manage files' },
      members: { title: 'Members ({{total}})', manage: 'Manage members' },
      description: 'Add a description for the group...',
      error:
        "Oops! There was an error loading group's details. <1>Try again?</1>",
      editDatesModal: {
        title: 'Edit group access dates',
        accessGrant: 'Access grant',
        accessExpiration: {
          label: 'Access expiration <1>(optional)</1>',
          message: 'Leave it blank if you want the access to never expiry',
        },
        cancel: 'Cancel',
        save: 'save changes',
        error:
          'Oops! There was an error updating access dates. <1>Try again?</1>',
        success: 'Successfully updated access dates',
      },
      earlyActivation: {
        title: 'Activate {{groupName}} earlier?',
        message: 'Original activation date: {{date}} ({{relative}})',
        cancel: 'Cancel',
        activate: 'Activate',
      },
      reactivate: {
        title: 'Reactivate {{groupName}}?',
        message:
          'You can reactivate with the same permissions it already had, or reset the permissions and give them separately',
        cancel: 'Cancel',
        reset: 'Reset permissions',
        reactivate: 'Reactivate',
      },
      dangerZone: {
        title: 'Danger zone',
        leaveGroup: {
          title: 'Leave group',
          description:
            'You will lose access to group content. The files, notes, and other items you shared will still be available to others.',
          button: 'Leave group',
          newOwnerModal: {
            title: 'Leaving group',
            description:
              'Ownership of this group will be transferred to another member.',
            label: 'New owner e-mail',
            cancel: 'cancel',
            continue: 'continue',
          },
          leavingWarnModal: {
            title: 'Leaving group',
            description:
              'Are you sure you want to leave {{groupName}}? This action cannot be undone.',
            cancel: 'cancel',
            leave: 'leave group',
            error:
              "Oops! There was an error and you didn't leave the group. <1>Try again?</1>",
            success: 'Successfully left the group',
          },
        },
        deleteGroup: {
          title: 'Delete group',
          description:
            'The group will be deleted permanently. This action <1>cannot</1> be undone.',
          button: 'Delete group',
          deleteWarningModal: {
            title: 'Deleting group',
            description:
              'Are you sure you want to delete {{groupName}} <3>forever</3>? This action cannot be undone.',
            disclaimer: 'Please type {{groupName}} to confirm.',
            cancel: 'cancel',
            delete: 'delete group',
            error:
              "Oops! There was an error and you didn't delete the group. <1>Try again?</1>",
            success: 'Successfully deleted the group',
          },
        },
      },
      errorUpdatingPicture:
        "There was an error updating group's picture! Please, try again",
    },
    groupFiles: {
      bulkEdit: 'Bulk edit',
      addFiles: 'Add files',
      table: {
        header: {
          file: 'File',
          read: 'Read',
          write: 'Write',
          readNotes: 'Read notes',
          createNotes: 'Create notes',
          delete: 'Delete file',
          updatePermissions: 'Update permissions',
        },
        empty: {
          text: 'No files are being shared with this group',
          button: 'Navigate files',
        },
        error: "Error updating file's permissions. Please try again",
        removeFileConfirmationModal: {
          title: 'Removing file',
          text: 'Users in the group will not have access to the file anymore.',
          continue: 'Continue?',
          remove: 'Remove file',
          error:
            'Oops! There was an error removing the file from the group. <1>Try again?</1>',
          success: 'File removed from group!',
        },
      },
      error: 'Unable to load group details. Please try again',
      empty: 'Seems like this folder is empty',
    },
    bulkEditing: {
      selectFiles: {
        title: 'Step 1 of 2 - Select files',
        selectAll: 'Select all',
        table: {
          header: {
            file: 'File',
          },
        },
        next: 'Next',
      },
      setPermissions: {
        title: 'Step 2 of 2 - Set permissions',
        read: 'Read - Allow users to read/access the documents',
        write: 'Write - Allow users to edit and/or overwrite the documents',
        readNotes:
          'Read notes - Allow users to read/access the notes of the documents',
        createNotes:
          'Create notes - Allow users to create or edit notes of the documents',
        delete: 'Delete files - Allow users to delete the documents',
        updatePermissions:
          'Update permissions - Allow users to update permissions of other users in the documents',
        remove:
          'Remove all permissions - Stop sharing the documents with the group',
        review: 'Review',
      },
      review: {
        title: 'Review editing',
        editing_one:
          'Editing <1>{{count}}</1> file to have <3>{{permissions}}</3> permissions',
        editing_other:
          'Editing <1>{{count}}</1> files to have <3>{{permissions}}</3> permissions',
        editing_all:
          'Editing <1>all</1> files to have <3>{{permissions}}</3> permissions',
        permissions: {
          permissions_read: 'read',
          permissions_write: 'write',
          permissions_read_notes: 'read notes',
          permissions_create_notes: 'create notes',
          permissions_delete: 'delete file',
          permissions_update_permissions: 'update permissions',
          permissions_noPermissions: 'no',
        },
        finish: 'Finish',
        loading: 'This may take a few minutes',
        error:
          "Oops! There's was an error updating the group. <1>Try again?</1>",
        success: {
          title_one: 'File edited successfully!',
          title_other: 'Files edited successfully!',
          manageFiles: 'Manage files',
          group: 'Access group',
        },
      },
    },
    groupMembers: {
      title: '{{groupName}} - Members',
      table: {
        header: {
          name: 'Name',
          email: 'E-mail',
          permissionLevel: 'Permission level',
        },
        permissionLevels: {
          admin: 'Admin',
          member: 'Member',
          manager: 'Manager',
          client: 'Client',
          owner: 'Owner',
        },
        remove: 'Remove user from group',
      },
      error: "Oops! We could not load group's members. <1>Try again?</1>",
      save: 'Save changes',
      changeMembersPermission: {
        success: 'New permissions saved!',
        error: 'There was an error saving new permissions. Try again',
      },
      warningModal: {
        text: 'You are inviting users from other organizations or that may not have an Filot account. Do you want to proceed?',
        button: 'Invite users',
        error:
          'Oops! We could not invite users to the group. <1>Try again?</1>',
        inviteSuccess: 'Users successfully invited!',
      },
      removeUserModal: {
        title: 'Removing user from group',
        text: 'Are you sure you want to remove {{name}} from group? This action cannot be undone.',
        submit: 'Remove',
        error: 'There was an error removing user. Please try again.',
      },
    },
    addFiles: {
      title: '{{groupName}} - Select files to add',
      errorLoading: 'Oops! We could not load files. <1>Try again?</1>',
      selectedFiles: {
        title: 'Selected files',
        button_one: 'Continue with {{count}} file',
        button_other: 'Continue with {{count}} files',
      },
      addFiles: 'Add files',
      confirmationModal: {
        title_one: 'You are adding {{count}} file to "{{groupName}}"',
        title_other: 'You are adding {{count}} files to "{{groupName}}"',
        text: 'Continue?',
        addFiles: 'Add files',
        cancel: 'Cancel',
      },
      errorAdding: 'Oops! Files could not be added. <1>Try again?</1>',
      success: 'Files added to group',
      unsavedWarningModal: {
        title: 'Discard unsaved changes?',
        discard: 'Discard changes',
        goBack: 'Keep adding files',
      },
    },
  },
  notFound: {
    title: 'Oh no!',
    text: "The page you are looking for does not exist or can't be reached.",
    possibleReasons: {
      title: 'Possible reasons',
      one: 'File/folder was deleted',
      two: 'Invalid address',
    },
    buttons: {
      home: 'home',
      help: 'help',
    },
  },
  profile: {
    title: 'My profile',
    profilePhoto: {
      title: 'Profile photo',
      change: 'Change photo',
      remove: 'remove',
      helper: 'Recommended photo size is 256 <1>x</1> 256 px',
    },
    form: {
      name: {
        label: 'Name',
        error: 'Please enter your full name',
      },
      phoneNumber: 'Phone number',
      email: 'E-mail',
      discard: 'discard',
      saveChanges: 'save changes',
      success: 'Profile info successfully updated',
      error: 'There was an error updating profile info. Please try again',
    },
    organization: {
      title: 'Organization',
      access: 'access organization page',
      noOrganization: 'You are not in an organization.',
      error: {
        title: 'Error loading organization',
        tryAgain: 'Try again',
      },
    },
  },
  tags: {
    title: 'Tags',
    merge: 'Merge tags',
    create: 'Create tag',
    empty: {
      text: "Looks like you haven't added any tags yet.",
      title: 'Tags can make organizing your files a breeze!',
      button: 'Explore files',
    },
    nonOrganized: {
      title: 'Join or create an organization to unlock this feature!',
      button: 'Access organizations',
    },
    error: 'Oops! We could not load your tags. <1>Try again?</1>',
    searchResults: {
      title: 'No tags found with that filter!',
      text: 'Try another search',
    },
    allFiles: 'See all files',
    noFiles: 'No files tagged with this tag yet!',
    contextMenu: {
      mergeWith: 'Merge with...',
    },
    mergeTagsModal: {
      title: 'Merge tags',
      firstTag: 'First tag',
      secondTag: 'Second tag',
      newTag: 'New tag name',
      cancel: 'Cancel',
      merge: 'merge',
      confirmation: {
        title:
          '"{{firstTag}}" and "{{secondTag}}" will be merged into "{{newTag}}"',
        text: 'This action cannot be undone. Continue?',
      },
      error: 'Oops! There was an error merging tags! <1>Try again?</1>',
      success: {
        title: 'Successfully merged tags into "{{newTag}}"!',
        access: 'Access tag',
        close: 'Close',
      },
    },
    createTagModal: {
      title: 'New tag',
      form: {
        tag: 'Your tag looks like this',
        name: 'Name',
        description: 'Description',
        backgroundColor: 'Background color',
        text: 'Text color',
      },
      cancel: 'Cancel',
      create: 'create',
      error: 'Oops! There was an error creating new tag! <1>Try again?</1>',
    },
    tagDetails: {
      header: {
        changeColor: 'change tag colors',
      },
      description: 'Add a description to this tag...',
      filesTable: {
        file: 'File',
        owner: 'Owner',
        sharedWith: 'Shared with',
        noFiles: {
          title:
            'Looks like there are no files with this tag yet! Tags help you organize and categorize your files, making them easier to find.',
          message: 'Try adding some files to get started!',
        },
      },
      changeColorModal: {
        title: 'Change colors',
        tag: 'Your tag looks like this',
        background: 'Background color',
        text: 'Text color',
        cancel: 'Cancel',
        apply: 'Apply',
        error: "Oops! There's was an error updating colors. <1>Try again?</1>",
        success: 'Tag colors updated!',
      },
      dangerZone: {
        title: 'Danger zone',
        deleteTag: {
          title: 'Delete tag',
          description:
            'All files with this tag will remain unchanged, but the tag will be removed. Other tags will not be affected.',
          button: 'delete tag',
          error: 'There was an error deleting the tag. Please, try again',
        },
      },
    },
  },
  fileVersions: {
    error: 'Error when loading file versions! Please <1>try again</1>',
    preview: {
      download: 'Download file',
      sync: 'Sync with cloud',
    },
    currentVersion: {
      title: 'Current Version',
      created: 'Created {{date}}',
      accepted: 'Accepted by {{name}}',
    },
    createNewVersion: 'Create new version',
    newVersionModal: {
      title: 'New version',
      name: 'Version name',
      base: 'Base version',
      cancel: 'Cancel',
      create: 'Create',
      error:
        'Oops! There was an error creating a new version. <1>Try again?</i>',
      success: 'Successfully created new version!',
    },
    proposedVersions: {
      title: 'Proposed versions',
      table: {
        name: 'Name',
        acceptedBy: 'Accepted by',
        approve: 'Accept version',
        deny: 'Deny version',
      },
      empty: {
        title: 'No proposed versions!',
        description:
          'You can propose versions from the "Working versions" table',
      },
      error: 'There was an error loading proposed versions. <1>Try again?</1>',
    },
    workingVersions: {
      title: 'Working versions',
      table: {
        name: 'Name',
        lastModified: 'Last modified',
        preview: 'Preview',
        context: {
          download: 'Download',
          upload: 'Upload to version',
          lock: 'Lock version',
          propose: 'Propose version',
          close: 'Close version',
        },
      },
      empty: {
        title: 'No working versions!',
        description: 'You can create a new version to edit the file',
      },
      error: 'There was an error loading working versions. <1>Try again?</1>',
    },
    closedVersions: {
      title: 'Closed versions',
      table: {
        name: 'Name',
        closed: 'Closed',
        preview: 'Preview',
        context: {
          download: 'Download',
          reopen: 'Reopen version',
          delete: 'Delete version',
        },
      },
      empty: {
        title: 'No closed versions!',
      },
      error: 'There was an error loading closed versions. <1>Try again?</1>',
    },
  },
  trash: {
    deletedFileDetails: {
      buttons: {
        restore: 'Restore file',
        deleteForever: 'Delete forever',
      },
      info: {
        title: 'File details',
        deletion: {
          title: 'Deletion',
          text: 'Deleted by: {{name}} at {{time}}',
        },
      },
    },
  },
};
