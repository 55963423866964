import { Range } from '@filot/types/api';

export interface RangedAuthConfig {
  audience: string;
}

export const configs: Record<Range, RangedAuthConfig> = {
  notes: {
    audience: 'https://logical.api.filot.io/',
  },
  organization: {
    audience: 'https://logical.api.filot.io/',
  },
  groups: {
    audience: 'https://logical.api.filot.io/',
  },
  files: {
    audience: 'https://logical.api.filot.io/',
  },
  users: {
    audience: 'https://logical.api.filot.io/',
  },
  tags: {
    audience: 'https://logical.api.filot.io/',
  },
};
