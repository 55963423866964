import { useTranslation } from 'react-i18next';

import { File } from '@app/services/files/types';
import Sync from '@assets/files/cloud-sync.svg?react';
import Download from '@assets/files/download.svg?react';
import Edit from '@assets/files/edit.svg?react';
// import ManageVersions from '@assets/files/history.svg?react';
import ZoomIn from '@assets/files/zoom-in.svg?react';
import ZoomOut from '@assets/files/zoom-out.svg?react';
import Back from '@assets/groups/back_arrow.svg?react';
import FileIcon from '@components/file-icon';
import PressableIcon from '@components/pressable-icon';

interface FilePreviewModalHeaderProps {
  file: File;
  onDownload: () => void;
  onSync: () => void;
  onManageVersions: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onClose: () => void;
  onEdit: () => void;
}

const ICON_SIZE = 20;

const FilePreviewModalHeader = ({
  // onManageVersions,
  onSync,
  onZoomIn,
  onZoomOut,
  onClose,
  onDownload,
  onEdit,
  file,
}: FilePreviewModalHeaderProps) => {
  const { t } = useTranslation();

  return (
    <header className="flex items-center justify-between">
      <div className="flex flex-1">
        <PressableIcon
          onClick={onClose}
          icon={<Back title="back" className="text-gray-200" />}
        />
        <span className="ml-md gap-x-sm flex items-center">
          <FileIcon type={file.type} />
          <h1 className="text-2xl text-gray-200">{file.fileName}</h1>
        </span>
      </div>
      <div
        className="gap-x-sm flex flex-row-reverse pr-7"
        onClick={(e) => e.stopPropagation()}
      >
        <PressableIcon
          onClick={onSync}
          icon={
            <Sync
              height={ICON_SIZE}
              width={ICON_SIZE}
              title="sync"
              className="text-gray-200"
            />
          }
          altText={t('fileList.filePreviewModal.sync')}
        />
        {/* <PressableIcon
          onClick={onManageVersions}
          icon={
            <ManageVersions
              height={ICON_SIZE}
              width={ICON_SIZE}
              title="manageVersions"
              className="text-gray-200"
            />
          }
          altText={t('fileList.filePreviewModal.manageVersions')}
        /> */}
        <PressableIcon
          onClick={onDownload}
          icon={
            <Download
              height={ICON_SIZE}
              width={ICON_SIZE}
              title="download"
              className="text-gray-200"
            />
          }
          altText={t('fileList.filePreviewModal.download')}
        />
        <PressableIcon
          onClick={onEdit}
          icon={
            <Edit
              height={ICON_SIZE}
              width={ICON_SIZE}
              title="edit"
              className="text-gray-200"
            />
          }
          altText={t('fileList.filePreviewModal.edit.title')}
        />
        <PressableIcon
          onClick={onZoomOut}
          icon={
            <ZoomOut
              height={ICON_SIZE}
              width={ICON_SIZE}
              title="zoomOut"
              className="text-gray-200"
            />
          }
          altText={t('fileList.filePreviewModal.zoomOut')}
        />
        <PressableIcon
          onClick={onZoomIn}
          icon={
            <ZoomIn
              height={ICON_SIZE}
              width={ICON_SIZE}
              title="zoomIn"
              className="text-gray-200"
            />
          }
          altText={t('fileList.filePreviewModal.zoomIn')}
        />
      </div>
    </header>
  );
};

export default FilePreviewModalHeader;
