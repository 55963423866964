import { Button, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { sizes } from '@app/config/theme';
import useAlert from '@app/hooks/useAlert';
import useAppJoyride from '@app/hooks/useAppJoyride';
import useErrorHandler from '@app/hooks/useErrorHandler';
import usePagination from '@app/hooks/usePagination';
import { useSearch } from '@app/hooks/useSearch';
import { File, Tag } from '@app/services/files/types';
import NoResults from '@assets/files/no_results.svg?react';
import ErrorIcon from '@assets/shared/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import OrganizationAcl from '@components/organization-acl';
import FileDetails from '@pages/files/components/file-details';
import FolderDetails from '@pages/files/components/folder-details';
import TagsEditorModal from '@pages/files/components/tags-editor-modal';
import useEditTags from '@pages/files/hooks/useEditTags';
import useRemoveTag from '@pages/files/hooks/useRemoveTag';

import CreateTagModal from './components/create-tag-modal';
import Empty from './components/empty';
import MergeTagsModal from './components/merge-tags-modal';
import NonOrganizedTags from './components/non-organized-tags';
import TagsListView from './components/tags-list-view';
import useTags from './hooks/useTags';

const Tags = () => {
  const { t } = useTranslation();
  const search = useSearch();

  const [fileDetail, setFileDetail] = useState<File | null>(null);
  const [isTagEditorOpen, setIsTagEditorOpen] = useState(false);
  const [initialTag, setInitialTag] = useState<Tag | null>(null);
  const [isMergeTagOpen, setIsMergeTagOpen] = useState(false);
  const [isCreateTagOpen, setIsCreateTagOpen] = useState(false);
  const deleteTagMutation = useRemoveTag();
  const {
    mutateAsync: editTags,
    isError: isEditTagError,
    reset: resetEditTags,
  } = useEditTags();
  const { display, Alert } = useAlert();
  useErrorHandler(isEditTagError, {
    callback: () => {
      display();
      resetEditTags();
    },
  });
  const {
    data: tags,
    isError,
    isLoading,
    refetch,
    isRefetching,
    onPageChange,
    total,
    isPlaceholderData,
    page,
  } = usePagination({ page: 1, rowsPerPage: 5 }, useTags, { filters: search });
  const { resume, advanceStep } = useAppJoyride();

  useEffect(() => {
    if (tags) {
      /* v8 ignore next 2 */
      advanceStep(6, 2);
      resume();
    }
  }, [advanceStep, resume, tags]);

  const handleAddTag = () => {
    setTimeout(() => {
      setIsTagEditorOpen(true);
    }, 200);
  };

  const handleEditTags = async ({
    tags: newTags,
    fileId,
  }: {
    fileId: string;
    tags: Tag[];
  }) => {
    setIsTagEditorOpen(false);
    await editTags({ tagNames: newTags.map((newTag) => newTag.name), fileId });
  };

  const FileOrFolderDetails = ({ file }: { file: File }) =>
    file.type === 'folder' ? (
      <FolderDetails id={file.id} onClose={() => setFileDetail(null)} />
    ) : (
      <FileDetails
        id={file.id}
        onClose={() => setFileDetail(null)}
        deleteTagControls={deleteTagMutation}
        onAddTag={handleAddTag}
      />
    );

  const content = useMemo(() => {
    if (isLoading || (isError && isRefetching)) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <ErrorIcon />
          <p className="!mt-lg !mr-1 !text-lg">
            <Trans i18nKey="tags.error">
              Oops! We could not load your tags.
              <span
                className="cursor-pointer text-lg underline"
                onClick={() => refetch()}
              >
                Try again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    if (tags && tags.length === 0) {
      if (search !== '') {
        return (
          <div className="flex flex-1 flex-col items-center justify-center">
            <NoResults width={sizes.xxl} height={sizes.xxl} />
            <Typography
              fontSize={20}
              className="!mt-screen !font-semibold !text-gray-700"
            >
              {t('tags.searchResults.title')}
            </Typography>
            <Typography fontSize={14} className="!mt-sm !text-gray-500">
              {t('tags.searchResults.text')}
            </Typography>
          </div>
        );
      }

      return (
        <div className="flex flex-1">
          <Empty />
        </div>
      );
    }

    /* v8 ignore next 4 */
    const handleOpenMergeTag = (tag: Tag) => {
      setIsMergeTagOpen(true);
      setInitialTag(tag);
    };

    if (tags) {
      return (
        <TagsListView
          tags={tags}
          onFileClick={setFileDetail}
          onOpenMergeTag={handleOpenMergeTag}
          onPageChange={(newPage) => onPageChange(newPage + 1)}
          page={page - 1}
          total={total}
          isPlaceholderData={isPlaceholderData}
        />
      );
    }

    return null;
  }, [
    isError,
    isLoading,
    isPlaceholderData,
    isRefetching,
    onPageChange,
    page,
    refetch,
    search,
    t,
    tags,
    total,
  ]);

  return (
    <>
      <div className="p-screen flex flex-1 flex-col overflow-auto">
        <OrganizationAcl fallback={<NonOrganizedTags />}>
          <div className="flex justify-between">
            <p className="text-3xl">{t('tags.title')}</p>
            <div className="gap-x-sm flex">
              <Button
                variant="outlined"
                className="!px-md"
                onClick={() => setIsMergeTagOpen(true)}
              >
                {t('tags.merge')}
              </Button>
              <Button
                variant="contained"
                className="!px-md"
                onClick={() => setIsCreateTagOpen(true)}
              >
                {t('tags.create')}
              </Button>
            </div>
          </div>
          {content}
        </OrganizationAcl>
      </div>

      {fileDetail ? <FileOrFolderDetails file={fileDetail} /> : null}
      {fileDetail ? (
        <TagsEditorModal
          isOpen={isTagEditorOpen}
          onClose={() => setIsTagEditorOpen(false)}
          fileId={fileDetail.id}
          onSubmit={({ tags: newTags }) =>
            handleEditTags({ fileId: fileDetail.id, tags: newTags })
          }
        />
      ) : null}
      <MergeTagsModal
        isOpen={isMergeTagOpen}
        onClose={() => setIsMergeTagOpen(false)}
        initialTag={initialTag ?? undefined}
      />
      <CreateTagModal
        isOpen={isCreateTagOpen}
        onClose={() => setIsCreateTagOpen(false)}
      />
      <Alert severity="error">
        {t('fileList.fileDetails.tagsEditorModal.error')}
      </Alert>
    </>
  );
};

export default Tags;
