import { ClickAwayListener, Divider, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { createContext, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useCloseable from '@app/hooks/useCloseable';
import { File } from '@app/services/files/types';
import Umbrella from '@assets/files/umbrella.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import FileIcon from '@components/file-icon';
import Loading from '@components/loading';

import useFolderDetails from '../hooks/useFolderDetails';

import FileDetailsPeople from './file-details-people';
import FolderDetailsInfo from './folder-details-info';

interface FolderDetailsProps {
  id: string;
  onClose: () => void;
}

export const FolderDetailsContext = createContext<null | File>(null);

const FolderDetails = ({ id, onClose }: FolderDetailsProps) => {
  const {
    isLoading,
    isError,
    data: folderDetails,
    refetch,
  } = useFolderDetails(id);
  const { t } = useTranslation();
  useCloseable({ onClose });

  const folderName = useMemo(
    () => folderDetails?.fileName.split('.')[0],
    [folderDetails?.fileName]
  );

  const Content = forwardRef<HTMLDivElement, unknown>((_, ref) => {
    if (isLoading) {
      return (
        <div className="mt-56 self-center" ref={ref}>
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="gap-y-md mt-56 self-center text-center" ref={ref}>
          <Umbrella className="mb-md mx-auto" />
          <Typography className="!text-lg">
            {t('fileList.folderDetails.error.title')}
          </Typography>
          <Typography
            className="!mb-md cursor-pointer !text-lg underline"
            onClick={() => refetch()}
          >
            {t('fileList.folderDetails.error.tryAgain')}
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    if (folderDetails) {
      return (
        <div ref={ref}>
          <FolderDetailsContext.Provider value={folderDetails}>
            <div className="flex flex-row items-end">
              <FileIcon type={folderDetails.type} width={42} height={42} />
              <Typography className="!ml-md !mb-1 !text-xl">
                {folderName}
              </Typography>
            </div>
            <Divider className="!my-sm" />
            <FileDetailsPeople
              usersId={Object.keys(folderDetails.people)}
              ownerId={folderDetails.owner}
              fileDetailsContext={FolderDetailsContext}
            />
            <Divider className="!mb-md !mt-sm" />
            <FolderDetailsInfo />
          </FolderDetailsContext.Provider>
        </div>
      );
    }

    return null;
  });

  const handleClickAway = ({ target }: MouseEvent | TouchEvent) => {
    if (
      target instanceof Element &&
      target.tagName.toLocaleLowerCase() !== 'body'
    ) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        id="folder-details"
        data-testid="folder-details"
        initial={{ translateX: 200 }}
        animate={{ translateX: 0 }}
        exit={{ translateX: 200 }}
        transition={{ type: 'keyframes' }}
        key={id}
        className="p-sm float-right flex h-full flex-col overflow-y-auto bg-white shadow-black drop-shadow-xl"
        style={{ width: 'min(33.33%, 30rem)' }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Content />
        </ClickAwayListener>
      </motion.div>
    </AnimatePresence>
  );
};

export default FolderDetails;
