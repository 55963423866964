import { Box, Button, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ErrorBubbleIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';
import StopClickPropagation from '@components/stop-click-propagation';

import useAcceptInvitation from '../hooks/useAcceptInvitation';

interface AcceptInviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  organizationName: string;
  invitationId: string;
}

const AcceptInviteModal = ({
  isOpen,
  onClose,
  organizationName,
  invitationId,
}: AcceptInviteModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    mutateAsync: acceptInvitation,
    isError,
    isPending,
    reset,
  } = useAcceptInvitation();

  const content = useMemo(() => {
    if (isPending) {
      return (
        <div className="flex h-48 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex h-64 flex-col items-center justify-center">
          <ErrorBubbleIcon className="mb-md mx-auto" />
          <p className="mx-lg mb-md text-center text-lg font-medium">
            <Trans i18nKey="organization.invitation.modal.error">
              Oops! There was an error accepting invitation.
              <span className="cursor-pointer underline" onClick={reset}>
                Try Again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    const handleAccept = async () => {
      await acceptInvitation(invitationId);
      onClose();
      await queryClient.invalidateQueries({ queryKey: ['organizations'] });
    };

    return (
      <>
        <p>
          {t('organization.invitation.modal.text', {
            organizationName,
          })}
        </p>
        <div className="mt-md flex justify-end">
          <Button variant="contained" onClick={handleAccept}>
            {t('organization.invitation.modal.continue')}
          </Button>
        </div>
      </>
    );
  }, [
    acceptInvitation,
    invitationId,
    isError,
    isPending,
    onClose,
    organizationName,
    queryClient,
    reset,
    t,
  ]);

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <StopClickPropagation>
        <Box className="w-medium-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
          <PressableIcon
            onClick={onClose}
            icon={<Close title="close" />}
            className="!absolute self-end !p-0"
          />
          <Typography className="!mb-sm !text-2xl">
            {t('organization.invitation.modal.title')}
          </Typography>
          {content}
        </Box>
      </StopClickPropagation>
    </Modal>
  );
};

export default AcceptInviteModal;
