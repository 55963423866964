import { useEffect } from 'react';

import { useAppLocation } from './useAppLocation';
import { useUpdateBreadcrumb } from './useBreadcrumb';

const usePathnameToBreadcrumb = () => {
  const dispatch = useUpdateBreadcrumb();
  const location = useAppLocation();

  useEffect(() => {
    const parts = location.pathname.split('/');

    const prefix = parts.splice(1, 1);
    if (parts.length > 1 && parts.at(-1) === '') {
      parts.pop();
    }

    if (prefix.some((entry) => entry !== 'files')) {
      return;
    }

    dispatch({
      type: 'SET_FOLDERS',
      payload:
        location.pathname === '/files' || location.pathname === 'files'
          ? ['']
          : parts.map((part) => decodeURIComponent(part)),
    });
  }, [dispatch, location.pathname]);
};

export default usePathnameToBreadcrumb;
