import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NonOrganized from '@assets/tags/non-organized.svg?react';

const NonOrganizedTags = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOrganizationNavigate = () => {
    navigate('/organizations');
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <NonOrganized width={456} height={248} />
      <p className="mb-lg text-2xl font-medium">
        {t('tags.nonOrganized.title')}
      </p>
      <Button
        variant="outlined"
        onClick={handleOrganizationNavigate}
        className="!px-md"
      >
        {t('tags.nonOrganized.button')}
      </Button>
    </div>
  );
};

export default NonOrganizedTags;
