import { AxiosResponse } from 'axios';

import { getEnv } from '@app/config/env';
import { getRangedAccessToken, getInstance } from '@app/utils/http-instance';
import { ServiceOptions, SuccessfulResponse } from '@filot/types/api';

import getFilesMock from './mock';
import {
  DeletedFileResponse,
  FilePreviewResponse,
  FileResponse,
  FilesApi,
  NotDeletedFileResponse,
  SharedFileResponse,
} from './types';

const getFilesService = ({
  url,
  getAccessTokenSilently,
}: ServiceOptions): FilesApi => {
  const {
    filot: { files },
  } = getEnv();

  return {
    ...getFilesMock(),
    get: async ({ path }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<
        FileResponse[]
      > | null>(`${url ?? files.domain}/api/v1/files/filter/path/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          path: path === '' ? '/' : path,
        },
      });

      return data;
    },

    uploadFile: async ({ file, name, path }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const formData = new FormData();
      formData.append('name', name);
      formData.append('path', path);
      formData.append('file', file);

      const { data } = await instance.post<
        FormData,
        AxiosResponse<SuccessfulResponse<NotDeletedFileResponse>>
      >(`${url ?? files.domain}/api/v1/files/create`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },

    getFileDetails: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/files/${fileId}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    getFolderDetails: async (folderId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/folders/${folderId}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    renameFile: async ({ fileId, newName }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/files/${fileId}/modify`,
        {
          rename: newName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    moveFile: async ({ fileId, newPath }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/files/${fileId}/modify`,
        {
          new_path: newPath,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    createFolder: async ({ folderName, location }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/folders/create`,
        {
          name: folderName,
          path: location,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    moveFileToTrash: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/files/${fileId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    moveFolderToTrash: async (folderId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/folders/${folderId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    download: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<File>(
        `${url ?? files.domain}/api/v1/files/${fileId}/download`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'blob',
        }
      );

      return data;
    },

    lockFile: async ({ fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? files.domain}/api/v1/files/${fileId}/lock `,
        {
          lock_duration_in_minutes: 240,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    unlockFile: async ({ fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? files.domain}/api/v1/files/${fileId}/unlock `,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    shareFile: async ({ users, access, expirationDate, startDate, fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/sharing/files/${fileId}/create`,
        {
          user_id: users[0],
          permissions_to_grant: access,
          activates_at: startDate,
          expiration_date: expirationDate,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    getSharedFile: async (sharedFileId, sharedFileType) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const filesOrFolders = sharedFileType === 'folder' ? 'folders' : 'files';

      const { data } = await instance.get<
        SuccessfulResponse<SharedFileResponse>
      >(
        `${url ?? files.domain}/api/v1/sharing/${filesOrFolders}/${sharedFileId}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    updateSharingPermissions: async ({ fileId, permission, userId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? files.domain}/api/v1/sharing/files/${fileId}/update`,
        {
          user_id: userId,
          permissions_to_grant: permission,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    revokeSharingPermissions: async ({ fileId, userId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance(
        `${url ?? files.domain}/api/v1/sharing/files/${fileId}/revoke`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            users: [userId],
          },
        }
      );
    },

    getFilePreview: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<
        SuccessfulResponse<FilePreviewResponse>
      >(`${url ?? files.domain}/api/v1/files/${fileId}/preview/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },

    deleteFilePreview: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? files.domain}/api/v1/files/${fileId}/preview/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    getDeletedFiles: async () => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<
        SuccessfulResponse<DeletedFileResponse[]>
      >(`${url ?? files.domain}/api/v1/files/deleted/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },

    updateContent: async ({ content, fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const formData = new FormData();
      formData.append('file', content);

      await instance.put(
        `${url ?? files.domain}/api/v1/files/${fileId}/content/modify`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    extendLock: async ({ fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? files.domain}/api/v1/files/${fileId}/lock/extend`,
        {
          lock_duration_in_minutes: 30,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },
  };
};

export { getFilesService };
