import { useAuth0 } from '@auth0/auth0-react';
import { Button, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import useUser from '@app/hooks/useUser';
import { UserUpdateRequest } from '@app/services/users/types';
import EditableField from '@components/editable-field';
import Loading from '@components/loading';
import LoadingButton from '@components/loading-button';

import useUpdateProfile from '../hooks/useUpdateProfile';

const ProfileForm = () => {
  const { t } = useTranslation();
  const auth = useAuth0();
  const { data: user, isFetching } = useUser(auth.user?.sub);
  const { control, formState, reset, handleSubmit, setValue } = useForm<
    UserUpdateRequest & { email: string }
  >({
    defaultValues: {
      name: user?.name ?? auth.user?.name ?? '',
      phoneNumber: user?.user_metadata?.phone_number ?? '',
      email: user?.email ?? '',
    },
    mode: 'onBlur',
  });
  const [safeValues, setSafeValues] = useState<
    Partial<UserUpdateRequest & { email: string }>
  >({
    name: user?.name ?? auth.user?.name ?? '',
    phoneNumber: user?.user_metadata?.phone_number ?? '',
    email: user?.email ?? '',
  });
  const { isError, isPending, mutateAsync: updateProfile } = useUpdateProfile();
  const { Alert: AlertSuccess, display: displaySuccess } = useAlert();
  const { Alert: AlertError, display: displayError } = useAlert();
  useErrorHandler(isError, {
    callback: () => {
      displayError();
    },
  });

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
      setValue('name', user.name);
      setValue('phoneNumber', user.user_metadata?.phone_number ?? '');
    }
  }, [setValue, user]);

  const handleUpdate = async (form: UserUpdateRequest) => {
    await updateProfile({ ...form, id: auth.user?.sub ?? '' });
    setSafeValues(form);
    displaySuccess();
    reset(form);
  };

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <div className="gap-y-md flex flex-1 flex-col">
        <FormControl fullWidth>
          <Controller
            name="name"
            control={control}
            rules={{
              validate: (value) => value.trim().split(' ').length > 1,
              required: true,
            }}
            render={({ field }) => (
              <EditableField
                {...field}
                dirty={formState.dirtyFields.name}
                label={t('profile.form.name.label')}
                error={!!formState.errors.name}
                helperText={
                  formState.errors.name && t('profile.form.name.error')
                }
                loading={isFetching}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <EditableField
                {...field}
                dirty={formState.dirtyFields.phoneNumber}
                label={t('profile.form.phoneNumber')}
                loading={isFetching}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                InputProps={{
                  readOnly: true,
                  endAdornment: isFetching ? <Loading size={12} /> : null,
                }}
                inputProps={{
                  className: '!text-black/65',
                }}
                label={t('profile.form.email')}
              />
            )}
          />
        </FormControl>
        <div className="mt-sm gap-x-sm flex self-end">
          <Button
            variant="outlined"
            onClick={() => reset(safeValues)}
            disabled={!formState.isDirty}
          >
            {t('profile.form.discard')}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!formState.isDirty || !formState.isValid}
            loading={isPending}
          >
            {t('profile.form.saveChanges')}
          </LoadingButton>
        </div>
      </div>
      <AlertSuccess severity="success">
        {t('profile.form.success')}
      </AlertSuccess>
      <AlertError severity="error">{t('profile.form.error')}</AlertError>
    </form>
  );
};

export default ProfileForm;
